export function routes($stateProvider) {
  $stateProvider
    .state('app.ativos.familias', {
      url: '/familias',
      abstract: true,
      template: '<div ui-view></div>'
    })
    .state('app.ativos.familias.list', {
      url: '/',
      controller: 'AtvFamiliasListController',
      controllerAs: 'vm',
      template: require('./directory/directory.view.html'),
      title: 'Famílias',
      role: 'atvListarFamilias'
    })
    .state('app.ativos.familias.details', {
      url: '/{id}',
      controller: 'AtvFamiliasDetailsController',
      controllerAs: 'vm',
      template: require('./details/details.view.html'),
      role: 'atvListarFamilias'
    })
    .state('app.ativos.familias.subfamilia', {
      url: '/{id}/subfamilia/{sf}',
      controller: 'AtvSubfamiliaDetailsController',
      controllerAs: 'vm',
      template: require('./subfamilias/details.view.html'),
      role: 'atvListarSubfamilias'
    })
  ;
}

routes.$inject = ['$stateProvider'];
