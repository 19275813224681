export default class ELVProcessFileUploaderController {
  constructor($dialog, ElvDocumentoTipo, FileUploader, ElvProcessoDocumento, UIService, pID, file) {
    this.dialog = $dialog;
    this.UI = UIService;
    this.pID = pID;
    this.ElvProcessoDocumento = ElvProcessoDocumento;
    this.progress = 0;
    this.uploading = false;
    this.replace = false;
    this.edit = angular.isDefined(file);
    this.fileEdit = file;
    this.editableFileTypeIndexes = [1, 2, 4, 5, 8]; // Ids de documentos editáveis

    this.uuid = this.generateUUID() + ".pdf";

    this.data = {
      documento: undefined,
      nome: this.uuid
    };

    ElvDocumentoTipo.find({
      filter: {
        where: {
          active: true,
          id: {inq: this.editableFileTypeIndexes}
        }
      }
    }).$promise.then(res => {
      this.loaded = true;
      this.tipos = res;
    });
    // Setup file uploader
    this.uploader = new FileUploader({
      url: '/api/Upload/elv/upload',
      queueLimit: 1
    });

    // Enforce PDF Files
    this.uploader.filters.push({
      name: 'verifyPDF',
      fn: function (item, options) {
        let type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
        return '|pdf|'.indexOf(type) !== -1;
      }
    });

    // Force queue limit = 1
    this.uploader.onAfterAddingFile = () => {
      if (this.uploader.queue.length > 1) {
        this.uploader.queue.splice(0, this.uploader.queue.length - 1);
      }
    };

    this.uploader.onBeforeUploadItem = (item) => {
      this.oldNome = item.file.name;
      let partes = item.file.name.split(".");
      item.file.name = this.generateUUID();
      if (partes.length > 1) item.file.name += "." + partes[partes.length - 1];
    //  item.file.name = this.uuid;
      if (this.replace) {
        // Remove older item
        this.replacefile.active = 0;
        this.ElvProcessoDocumento.upsert(this.replacefile);
      }
      this.ElvProcessoDocumento.create({
        id: 0,
        processoId: this.pID,
        tipoDocumento: this.edit ? this.fileEdit.tipoDocumento : this.data.documento.id,
        nome: item.file.name,
        nomeAntigo: this.oldNome,
        active: true
      }).$promise.then((res) => {
        this.entry = res;
      });
    };

    this.uploader.onWhenAddingFileFailed = () => {
      this.UI.addToast('Erro ao adicionar ficheiro');
      this.cancel();
    };

    this.uploader.onSuccessItem = () => {
      this.dialog.close({
        replace: this.replace,
        data: this.data
      });
    };

    this.uploader.onProgressItem = (item, progress) => {
      this.progress = progress;
    };

    this.uploader.onErrorItem = () => {
      // Remove entry of BD
      if (this.entry) {
        this.entry.active = 0;
        this.entry.$save();
      }
    }
  }

  ok = () => {
    if (this.edit === false) {
      // CHeck if file of this type exists
      this.ElvProcessoDocumento.find({
        filter: {
          where: {
            processoId: this.pID,
            tipoDocumento: this.data.documento.id,
            active: true
          }
        }
      }).$promise.then((file) => {
          //  Show progress for upload
          this.uploading = true;
          this.progress = 0;
          // Upload file
          this.uploader.uploadAll();
      });
    } else {
      // Editing, replace file
      //  Show progress for upload
      this.uploading = true;
      this.replace = true;
      this.replacefile = this.fileEdit;
      this.progress = 0;
      // Upload file
      this.uploader.uploadAll();
    }
  };

  cancel = () => {
    this.dialog.dismiss('cancel');
  };

  generateUUID = () => {
    let uuid = "", i, random;
    for (i = 0; i < 32; i++) {
      random = Math.random() * 16 | 0;
      if (i == 8 || i == 12 || i == 16 || i == 20) {
        uuid += "-";
      }
      uuid += (i == 12 ? 4 : (i == 16 ? (random & 3 | 8) : random)).toString(16);
    }
    return uuid;
  };



}

ELVProcessFileUploaderController.$inject = ['$dialog', 'ElvDocumentoTipo', 'FileUploader', 'ElvProcessoDocumento', 'UIService', 'pID', 'file'];
