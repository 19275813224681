import angular from 'angular';
import {routes} from './relatorios.routes';
import LmeDetailRelatoriosController from "./details/details.controller";
import LmeDirectoryRelatoriosController from "./directory/directory.controller";

export default angular.module('app.laboratory.relatorios', [])
  .config(routes)
  .controller('LmeDetailRelatoriosController', LmeDetailRelatoriosController)
  .controller('LmeDirectoryRelatoriosController', LmeDirectoryRelatoriosController)
  .filter('lmeFormat', function() {
    return function(data) {
      let e = 'IEP';
      if (data.empresaId === 1) {
        e = 'IEP';
      } else if (data.empresaId === 2) {
        e = 'AJL';
      }
      let t;
      if (data.tipoId)
        t = data.tipoId === 1 ? 'M' : 'E';
      else
        if (data.tipo)
          t = data.tipo === "Metrologia" ? 'M' : 'E';
      let ano = data.iidAno;
      let num = ("0000" + data.iidProc) .slice(-4);
      return `${e}-${t}-${ano}-${num}`;
    }
  })
  .name;
