import angular from 'angular';

import {routes} from "./agendamentos.routes";
import DirectorySchedulingController from "./directory/directory.controller";
import CalendarizacaoController from "./calendarizacao/calendar.controller";

export default angular.module('app.agendamentos', [])
  .config(routes)
  .controller('DirectorySchedulingController', DirectorySchedulingController)
  .controller('CalendarizacaoController', CalendarizacaoController)
  .name;
