export function routes($stateProvider) {
  $stateProvider
    .state('app.noticias', {
      url: '/noticias',
      abstract: true,
      template: '<div ui-view></div>'
    })
    .state('app.noticias.list', {
      url: '/',
      title: 'Gestão de Notícias',
      template: require('./directory/directory.view.html'),
      controller: 'DirectoryNewsController',
      role: 'newsIeList',
      controllerAs: 'vm',
    })
    .state('app.noticias.create', {
      url: '/adicionar',
      title: 'Adicionar Notícia',
      template: require('./create/create.view.html'),
      controller: 'CreateNewsController',
      role: 'newsIeEdit',
      controllerAs: 'vm'
    })
    .state('app.noticias.edit', {
      url: '/{id}',
      title: 'Editar Notícia',
      template: require('./create/create.view.html'),
      controller: 'EditNewsController',
      role: 'newsIeEdit',
      controllerAs: 'vm'
    });
}

routes.$inject = ['$stateProvider'];
