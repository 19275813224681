export function routes($stateProvider) {
  $stateProvider
    .state('app.pad.fornecedores', {
      url: '/fornecedores',
      abstract: true,
      template: '<div ui-view></div>'
    })
    .state('app.pad.fornecedores.list', {
      url: '/',
      controller: 'PadFornecedoresListController',
      controllerAs: 'vm',
      template: require('./directory/directory.view.html'),
      title: 'Fornecedores',
      role: '$internal',
      params: {
        state: {
          value: 1,
          dynamic: true
        },
        page: {
          value: 1,
          dynamic: true
        },
        items: {
          value: 20,
          dynamic: true
        },
        order: {
          value: "id",
          dynamic: true
        },
        sort: {
          value: "asc",
          dynamic: true
        },
        filter: {
          dynamic: true
        }
      }
    })
    .state('app.pad.fornecedores.details', {
      url: '/{id}',
      controller: 'PadFornecedoresDetailsController',
      controllerAs: 'vm',
      template: require('./details/details.view.html'),
      role: '$internal',
    })
  ;
}

routes.$inject = ['$stateProvider'];
