export default class RhEventController {
  constructor($state, UIService, RhEventService) {
    this.$state = $state;
    this.UI = UIService;
    this.RhEventService = RhEventService;
    // Para já, evento cravado a 1 (Comemoração 250 colaboradores)
    this.id = 1;
    this.identifier = "";
    this.tableLoading = true;
    this.eventLoading = true;
    this.focusMe = true;
    // Table data
    this.displayColumns = [
    {
      displayName: 'Data',
      name: 'data',
      visible: true,
      sortable: false
    },
    {
      displayName: 'Hora',
      name: 'hora',
      visible: true,
      sortable: false
    },
    {
      displayName: 'Colaborador',
      name: 'funcionario',
      visible: true,
      sortable: false
    },
    {
      displayName: 'Nº Cartão de Acesso',
      name: 'cartaoAcesso',
      visible: true,
      sortable: false
    }];


    this.loadData();
  }

  delete = (row) => {
    let whoToDelete = row.funcionario ? row.funcionario.name : `Desconhecido (${row.cartaoAcesso})`;
    this.UI.showConfirm(`Deseja remover ${whoToDelete} do evento?`).then(_ => {
      if (_) {
        this.RhEventService.unregisterUserOfEvent(row).then(r => {
          this.UI.addToast(r);
          this.loadData();
        }).catch(e => {
          this.UI.addToast(e);
        });
      }
    });
  }

  loadData = () => {
    this.RhEventService.getEvento(this.id).then(r => {
      this.event = r;
      this.eventLoading = false;
    });

    this.RhEventService.getRegistosEvento(this.id).then(r => {
      this.data = r;
      this.total = r.length;
      this.tableLoading = false;
    });

  }

  register = () => {
    if (this.identifier == "") {
      this.UI.addToast("Não foi possível ler identificador");
      return;
    }
    this.RhEventService.register(this.id, this.identifier).then(r => {
      this.UI.addToast(r);
      this.loadData();
    }).catch(e => {
      this.UI.addToast(e);
    });
    this.identifier = "";
    this.focusMe = true;
  }

  isColumnVisible = (item) => {
    return true;
  }
}

RhEventController.$inject = ['$state', 'UIService', 'RhEventService'];
