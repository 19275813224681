import angular from 'angular';

import {routes} from './mensagens.routes';
import DirectoryMensagensController from "./directory/directory.controller";



export default angular.module('app.mensagens', [])
        .config(routes)
        .controller('DirectoryMensagensController', DirectoryMensagensController)
        .name;
