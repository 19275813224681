export function routes($stateProvider) {
  $stateProvider
    .state('app.requisitantes', {
      url: '/requisitantes',
      abstract: true,
      template: '<div ui-view></div>'
    })
    .state('app.requisitantes.list', {
      url: '/',
      title: 'Gestão de Clientes',
      template: require('./directory/directory.view.html'),
      controller: 'DirectoryRequisitantesController',
      role: 'clientesList',
      controllerAs: 'vm',
    })
    .state('app.requisitantes.edit', {
      url: '/{id}',
      title: 'Editar utilizador',
      template: require('./edit/edit.view.html'),
      controller: 'EditRequisitantesController',
      role: 'clientesEdit',
      controllerAs: 'vm',
    });
}

routes.$inject = ['$stateProvider'];
