import React, { useEffect } from 'react';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, MenuItem, TextField } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid';
import * as yup from 'yup';
import { useFormik } from 'formik';
const DialogDetailsProcesso = ({ open, initial, onClose, ords, distribuidores, tiposGas, tiposInstalacao, tiposInspecao }) => {
    const validationSchema = yup.object({
        cui: yup.string().matches(/^[A-Za-z]{2}\d{16}[A-Za-z]{2}$/, 'CUI Inválido').required(),
        observacoes: yup.string().notRequired(),
        distribuidorId: yup.number().oneOf(distribuidores.map(x => x.id)).required("Distribuidor é obrigatório"),
        distribuidorPresente: yup.number().required(),
        distribuidorTecnicoNome: yup.string().when('distribuidorPresente', {
            is: (val) => val === 1,
            then: (schema) => schema.required('Nome do Técnico do Distribuidor é obrigatório'),
            otherwise: (schema) => schema.nullable().notRequired(),
        }),
        ordId: yup.number().oneOf(ords.map(x => x.id)).required("ORD é obrigatório"),
        tipoGasId: yup.number().oneOf(tiposGas.map(x => x.id)).required("Tipo de Gás é obrigatório"),
        tipoInstalacaoId: yup.number().oneOf(tiposInstalacao.map(x => x.id)).required("Tipo de Instalação é obrigatório"),
        tipoInspecaoId: yup.number().oneOf(tiposInspecao.map(x => x.id)).required("Tipo de Inspeção é obrigatório"),
    });
    const formik = useFormik({
        initialValues: {},
        validationSchema: validationSchema,
        validateOnMount: false,
        onSubmit: async (values) => {
            // Return uppercase CUI
            values.cui = values.cui.toUpperCase();
            if (!values.distribuidorPresente)
                values.distribuidorTecnicoNome = null;
            onClose(values);
        }
    });
    useEffect(() => {
        formik.resetForm();
        formik.setValues(initial);
    }, [initial]);
    return (<form>
      <Dialog open={open} maxWidth={'lg'} fullWidth>
        <DialogTitle>Editar Detalhes do Processo</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={6} md={6} xs={12}>
              <TextField label='Distribuidor' name='distribuidorId' defaultValue={0} value={formik.values?.distribuidorId} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.distribuidorId && Boolean(formik.errors.distribuidorId)} helperText={formik.touched.distribuidorId && formik.errors.distribuidorId} select>
                <MenuItem value={undefined} disabled></MenuItem>
                {distribuidores && distribuidores.map(x => <MenuItem value={x.id} key={x.id}>{x.designacao}</MenuItem>)}
              </TextField>
            </Grid>
            <Grid lg={6} md={6} xs={12}>
              <TextField label='ORD' name='ordId' defaultValue={0} value={formik.values?.ordId} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.ordId && Boolean(formik.errors.ordId)} helperText={formik.touched.ordId && formik.errors.ordId} select>
                <MenuItem value={undefined} disabled></MenuItem>
                {ords && ords.map(x => <MenuItem value={x.id} key={x.id}>{x.designacao}</MenuItem>)}
              </TextField>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid display="flex" justifyContent="left" alignItems="center" lg={3} md={3} xs={12}>
              <FormGroup>
                <FormControlLabel control={<Checkbox checked={Boolean(formik.values?.distribuidorPresente)} onChange={event => {
                formik.setFieldValue('distribuidorPresente', Number(Boolean(event.target.checked)), true);
            }} name="checked" color="primary"/>} label="Distribuidor Presente?"/>
              </FormGroup>
            </Grid>
            {Boolean(formik.values?.distribuidorPresente) && (<Grid lg={9} md={9} xs={12}>
                <TextField label='Nome do Técnico do Distribuidor' name='distribuidorTecnicoNome' defaultValue={formik.values?.distribuidorTecnicoNome} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.distribuidorTecnicoNome && Boolean(formik.errors.distribuidorTecnicoNome)} helperText={formik.touched.distribuidorTecnicoNome && formik.errors.distribuidorTecnicoNome}/>
              </Grid>)}
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={12} md={12} xs={12}>
              <TextField label='CUI' name='cui' defaultValue={formik.values?.cui} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.cui && Boolean(formik.errors.cui)} helperText={formik.touched.cui && formik.errors.cui}/>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={4} md={4} xs={12}>
              <TextField label='Tipo de Gás' name='tipoGasId' defaultValue={0} value={formik.values?.tipoGasId} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.tipoGasId && Boolean(formik.errors.tipoGasId)} helperText={formik.touched.tipoGasId && formik.errors.tipoGasId} select>
                <MenuItem value={undefined} disabled></MenuItem>
                {tiposGas && tiposGas.map(x => <MenuItem value={x.id} key={x.id}>{x.designacao}</MenuItem>)}
              </TextField>
            </Grid>
            <Grid lg={4} md={4} xs={12}>
              <TextField label='Tipo de Instalação' name='tipoInstalacaoId' defaultValue={0} value={formik.values?.tipoInstalacaoId} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.tipoInstalacaoId && Boolean(formik.errors.tipoInstalacaoId)} helperText={formik.touched.tipoInstalacaoId && formik.errors.tipoInstalacaoId} select>
                <MenuItem value={undefined} disabled></MenuItem>
                {tiposInstalacao && tiposInstalacao.map(x => <MenuItem value={x.id} key={x.id}>{x.designacao}</MenuItem>)}
              </TextField>
            </Grid>
            <Grid lg={4} md={4} xs={12}>
              <TextField label='Tipo de Inspeção' name='tipoInspecaoId' defaultValue={0} value={formik.values?.tipoInspecaoId} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.tipoInspecaoId && Boolean(formik.errors.tipoInspecaoId)} helperText={formik.touched.tipoInspecaoId && formik.errors.tipoInspecaoId} select>
                <MenuItem value={undefined} disabled></MenuItem>
                {tiposInspecao && tiposInspecao.map(x => <MenuItem value={x.id} key={x.id}>{x.designacao}</MenuItem>)}
              </TextField>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid display="flex" justifyContent="left" alignItems="center" lg={12} md={12} xs={12}>
              <FormGroup>
                <FormControlLabel control={<Checkbox checked={Boolean(formik.values?.agendamentoNaHora)} onChange={event => {
                formik.setFieldValue('agendamentoNaHora', Boolean(event.target.checked), true);
            }} name="checked" color="primary"/>} label="Agendamento Na Hora?"/>
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={12} md={12} xs={12}>
              <TextField label='Observações' name='observacoes' defaultValue={formik.values?.observacoes} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.observacoes && Boolean(formik.errors.observacoes)} helperText={formik.touched.observacoes && formik.errors.observacoes}/>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type='button' onClick={() => onClose(undefined)}>
            Cancelar
          </Button>
          <Button onClick={() => {
            formik.handleSubmit();
        }} disabled={!formik.isValid || !formik.dirty || formik.isSubmitting} variant='contained'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </form>);
};
export default DialogDetailsProcesso;
