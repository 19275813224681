export function routes($stateProvider) {
  $stateProvider
    .state('app.ativos.fornecedores', {
      url: '/fornecedores',
      abstract: true,
      template: '<div ui-view></div>'
    })
    .state('app.ativos.fornecedores.list', {
      url: '/',
      controller: 'AtvFornecedoresListController',
      controllerAs: 'vm',
      template: require('./directory/directory.view.html'),
      title: 'Fornecedores',
      role: 'atvListarFornecedores'
    })
    .state('app.ativos.fornecedores.details', {
      url: '/{id}',
      controller: 'AtvFornecedoresDetailsController',
      controllerAs: 'vm',
      template: require('./details/details.view.html'),
      role: 'atvListarFornecedores'
    })
  ;
}

routes.$inject = ['$stateProvider'];
