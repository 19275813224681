import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem, CircularProgress } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
const DialogSchedule = ({ initial, OblerRevampService, onClose, ...props }) => {
    const [tecnicos, setTecnicos] = useState([]);
    const [loading, setLoading] = useState(true);
    const form = useFormik({
        initialValues: {
            data: undefined,
            tecnicoId: undefined
        },
        validationSchema: yup.object({
            data: yup.date().typeError('Data/hora inválida').required('O campo é obrigatório'),
            tecnicoId: yup.number().required('O campo é obrigatório')
        }),
        validateOnMount: false,
        onSubmit: async (values) => {
            onClose(values);
        }
    });
    const getTecnicos = async (date) => {
        setLoading(true);
        try {
            const l = await OblerRevampService.findTecnicos(date);
            setTecnicos(l);
            setLoading(false);
        }
        catch (e) {
            setTecnicos([]);
        }
    };
    // Formik fix dialog reuse
    useEffect(() => {
        let init = initial || {
            data: undefined,
            tecnicoId: undefined
        };
        /*
        if (init.tecnicoId == undefined) {
          init.tecnicoId = 0;
        }
          */
        form.resetForm();
        form.setValues(init);
    }, [initial]);
    return (<Dialog {...props}>
      <form>
        <DialogTitle>Agendar visita técnica</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={4} md={4} xs={12}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker label='Data/Hora' format='DD/MM/YYYY HH:mm' value={form.values?.data} onChange={value => {
            if (value.isValid) {
                getTecnicos(value);
            }
            form.setFieldValue('data', value, true);
        }} slotProps={{
            textField: {
                variant: 'outlined',
                name: 'data',
                onBlur: form.handleBlur,
                error: form.touched.data && Boolean(form.errors.data),
                fullWidth: true,
                helperText: form.touched.data && Boolean(form.errors.data) && String(form.errors.data) // Use boolean cause string([]) = "undefined"
            }
        }}/>
              </LocalizationProvider>
            </Grid>
            <Grid lg={8} md={8} xs={12}>
              <TextField disabled={loading} InputProps={{
            endAdornment: <React.Fragment>{loading ? <CircularProgress color='inherit' size={20}/> : null}</React.Fragment>
        }} label='Técnico' name='tecnicoId' value={form.values?.tecnicoId} onChange={form.handleChange} onBlur={form.handleBlur} fullWidth error={form.touched.tecnicoId && Boolean(form.errors.tecnicoId)} helperText={form.touched.tecnicoId && form.errors.tecnicoId} select>
                <MenuItem value={undefined} disabled></MenuItem>
                {tecnicos &&
            tecnicos.map(x => (<MenuItem value={x.id} key={x.id}>
                      {x.name}
                    </MenuItem>))}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type='button' onClick={() => onClose(undefined)}>
            Cancelar
          </Button>
          <Button onClick={() => {
            form.handleSubmit();
        }} disabled={!form.isValid || !form.dirty || form.isSubmitting} variant='contained'>
            OK
          </Button>
        </DialogActions>
      </form>
    </Dialog>);
};
export default DialogSchedule;
