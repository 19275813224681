export default class DashboardPADService {
  constructor($q, $filter, AuthenticationService, PadProcesso) {
    this.$q = $q;
    this.$filter = $filter;
    this.user = AuthenticationService.getUser();
    this.PadProcesso = PadProcesso;
  }

  getDashboard = () => {
    let defer = this.$q.defer();

    this.PadProcesso.dashboard({params: {userId: this.user.id}}).$promise.then(padData => {
      padData.error = null;
      // Deal with dashboard text strings here
      padData.awaitingApprovalText = `Aguarda${padData.awaitingApproval === 1? '' : 'm'} ser${padData.awaitingApproval === 1? '' : 'em'} avaliado${padData.awaitingApproval === 1? '' : 's'}`;
      padData.pendentesText = `Pendente${padData.awaitingApproval === 1? '' : 's'} da sua ação`;
      padData.toApproveText = `Aguarda${padData.awaitingApproval === 1? '' : 'm'} a sua aprovação`;
      defer.resolve(padData);
    }).catch(err => {
      let padData = {name: this.user.name};
      if (err.status === 401) {
        padData.data = null;
        padData.error = "Não tem permissões para ver esta informação. Recarregue a página.";
      } else {
        padData.data = null;
        padData.error = "Ocorreu um erro na obtenção de dados de PADs. Verifique a sua ligação.";
      }
      defer.resolve(padData); // Resolve, so we can show a proper error in dashboard
    });
    return defer.promise;
  };
}

DashboardPADService.$inject = ['$q', '$filter', 'AuthenticationService', 'PadProcesso'];
