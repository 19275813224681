export default class LayoutController {
  constructor($rootScope, AuthenticationService, AuthorizationService, $state, UIService) {

    this.AuthenticationService = AuthenticationService;
    this.AuthorizationService = AuthorizationService;
    this.$state = $state;
    this.UI = UIService;
    this.environment = '';

    $rootScope.$on('$receivedServerStatus', (evt, data) => {
      this.parseMenu();
      this.status = data;
      this.environment = this.parseEnv();
    });
    this.menuItems = [];
    this.user = AuthenticationService.getUser();
    this.parseMenu();
  }

  logout = () => {
    this.AuthenticationService.logout();
  };

  appUrl = () => {
    if (this.status && this.status.hasOwnProperty('environment')) {
      if (this.status.environment === 'production') {
        return "https://apps.streamline.pt/public/package/pt.streamline.iep.sgimobile/prod";
      } else if (this.status.environment === 'staging') {
        return "https://apps.streamline.pt/public/package/pt.streamline.iep.sgimobile/staging";
      } else {
        return "https://apps.streamline.pt/public/package/pt.streamline.iep.sgimobile/dev";
      }
    }
  };

  parseEnv = () => {
    if (this.status && this.status.hasOwnProperty('environment')) {
      if (this.status.environment === 'production') {
        return "Produção";
      } else if (this.status.environment === 'staging') {
        return "Staging";
      } else if (this.status.environment === 'local') {
        return "Local";
      } else {
        return "Desenvolvimento";
      }
    }
  };

  getStatusPrimavera = () => {
    let statusPrimavera = "Ligação Primavera: ";
    if (this.status && this.status.hasOwnProperty('statusPrimavera')) {
      if (this.status.statusPrimavera['IEP'] === this.status.statusPrimavera['AJL'] && this.status.statusPrimavera['OBLER'])
        statusPrimavera += this.status.statusPrimavera['IEP'];
      else
        statusPrimavera += "IEP: " + this.status.statusPrimavera['IEP'] + "AJL: " + this.status.statusPrimavera['AJL'] + "OBLER: " + this.status.statusPrimavera['OBLER'];
    }
    return statusPrimavera;
  };

  parseMenu = () => {
    const jsonMenu = require('../navigation.json');
    // Getting list of all states, so that role will be loaded from states...
    let states = this.$state.get();
    states = _.filter(states, s => {
      // Ignore abstract routes and placeholders
      return s.hasOwnProperty('template');
    });

    // Iterate the sidebar options through the states, to get the required role for it
    let menu = [];
    jsonMenu.forEach(section => {
      if (section.hasOwnProperty('sections')) {
        let k = [];
        section.sections.forEach(o => {
          if (o.hasOwnProperty("link")) {
            if (o.hasOwnProperty('role')) {
              if (this.AuthorizationService.canPerform(o.role))
                k.push(o)
            } else k.push(o);
          } else {
            let s = _.find(states, {
              name: o.state
            });
            if (angular.isDefined(s)) {
              if (s.hasOwnProperty('role')) {
                if (this.AuthorizationService.canPerform(s.role))
                  k.push(o)
              } else k.push(o);
            } else {
              // Do nothing... :)
            }
          }

        });
        if (k.length > 0) {
          section.sections = k;
          menu.push(section);
        }
      } else {
        let s = _.find(states, {
          name: section.state
        });
        if (angular.isDefined(s)) {
          if (s.hasOwnProperty('role')) {
            if (this.AuthorizationService.canPerform(s.role))
              menu.push(section)
          } else menu.push(section);
        } else {
          if (section.hasOwnProperty("link")) {
            if (section.hasOwnProperty("app")) {
              section.link = this.appUrl();
            }
            menu.push(section);
          } else {
            // Do nothing... :)
          }
        }
      }
    });
    this.menuItems = menu;
  };
}

LayoutController.$inject = ['$rootScope', 'AuthenticationService', 'AuthorizationService', '$state', 'UIService'];
