import { routes } from './routes'

import OblArmazemListController  from './list/controller';
import OblArmazemDetailsController from './details/controller';

export default angular.module('app.obler.armazem', [])
  .config(routes)
  .controller('OblArmazemListController', OblArmazemListController )
  .controller('OblArmazemDetailsController', OblArmazemDetailsController)
  .name;
