export default class AdministrationService {

  constructor(Rolemap, Role, Users, Group, Rolegroup, $q) {
    this.Rolemap = Rolemap;
    this.Role = Role;
    this.Users = Users;
    this.Group = Group;
    this.Rolegroup = Rolegroup;
    this.$q = $q;
  }

  /* Role related functions */

  getGroups = () => {
    let defer = this.$q.defer();
    this.Group.find({}).$promise.then((groups) => {
      defer.resolve(groups);
    });
    return defer.promise;
  };

  getGroupInfo = (roleId) => {
    let defer = this.$q.defer();
    this.Group.findOne({
      filter: {
        where: {
          id: roleId,
          active: true
        },
        include: [{
          relation: 'usergroup',
          scope: {include: "user"}
        }, {
          relation: 'roles',
          scope: {include: 'feature'}
        }]
      }
    }).$promise.then(r => {
      let users = [];
      r.usergroup.forEach(u => {
        users.push(u.user);
      });
      r.users = users;
      defer.resolve(r);
    });
    return defer.promise;
  };

  getGroupRoles = (roleId) => {
    let defer = this.$q.defer();
    this.Rolegroup.find({
      filter: {
        where: {
          groupId: roleId
        },
        include: {
          relation: 'role',
          scope: {include: 'feature'}
        }
      }
    }).$promise.then(roles => {
      defer.resolve(roles)
    });
    return defer.promise;
  };

  updateGroup = (group, featuredRoles, users) => {

    let defer = this.$q.defer();
    let roles = [];
    // Ungroup the roles
    featuredRoles.forEach(f => {
      f.roles.forEach(role => {
        roles.push(role);
      });
    });

    this.Group.updateInfo({id: group.id, users: users, roles: roles}).$promise.then((result) => {
      group.edited = new Date();
      group.$save().then(() => {
        defer.resolve();
      });
    });

    return defer.promise;
  };

  groupHasUsers = (id) => {
    let defer = this.$q.defer();
    this.Group.findOne({
      filter: {
        where: {
          id: id
        },
        include: {
          relation: "usergroup",
          scope: {include: "user"}
        }
      }
    }).$promise.then((res) => {
      if (res.usergroup.length > 0) {
        defer.resolve(true);
      } else {
        defer.resolve(false);
      }
    });
    return defer.promise;
  };

  createGroup = (group) => {
    let defer = this.$q.defer();

    group.edited = new Date();

    this.Group.create(group).$promise.then((data) => {
      defer.resolve(data);
    }).catch(err => {
      defer.reject(err);
    });

    return defer.promise;
  };

  /* User related functions */
  getUsersInRole = (roleId) => {
    let defer = this.$q.defer();
    this.Group.find({
      filter: {
        fields: {id: true},
        where: {
          id: roleId
        },
        include: {
          relation: "usergroup",
          scope: {
            include: "user"
          }
        }
      }
    }).$promise.then(role => {
      let users = [];
      role.forEach(r => {
        r.usergroup.forEach(u => {
          users.push(u.user);
        })
      });
      defer.resolve(users);
    });
    return defer.promise;
  };

  getUsersNotInRole = (roleId) => {
    let defer = this.$q.defer();

    this.getUsersInRole(roleId).then(users => {
      let ids = _.map(users, u => {
        return u.id;
      });

      this.Users.find({
        filter: {
          where: {
            id: {nin: ids}
          }
        }
      }).$promise.then(list => defer.resolve(list));
    });

    return defer.promise;
  };

  getUsers = () => {
    let defer = this.$q.defer();
    this.Users.find({}).$promise.then((users) => {
      defer.resolve(users);
    });
    return defer.promise;
  };

  getUser = (id) => {
    let defer = this.$q.defer();
    this.Users.findOne({
      filter: {
        where: {
          id: id
        },
        include: ['ausencias', 'groups', {
          relation: 'funcionariosEntidadesProprietarias',
          scope: {
            where: {
              active: 1
            },
            include: [{
              relation: 'entidadeProprietaria',
              scope: {
                where: {
                  active: 1
                }
              }
            }, {
              relation: 'atribuidoPor',
              scope: {
                where: {
                  active: 1
                }
              }
            }, {
              relation: 'removidoPor',
              scope: {
                where: {
                  active: 1
                }
              }
            }]
          }
        }]
      }
    }).$promise.then((user) => {
      defer.resolve(user);
    });
    return defer.promise;
  };

  createUser = (data) => {
    let defer = this.$q.defer();

    // Populate data
    data.blocked = false;
    data.createdAt = new Date();

    this.Users.upsert(data).$promise.then((result) => {
      defer.resolve(result);
    }).catch(err => {
      defer.reject(err);
    });
    return defer.promise;
  };

  removeUser = (user) => {
    let defer = this.$q.defer();

    this.Users.destroyById({id: user.id}).$promise.then((result) => {
      defer.resolve(result);
    }).catch(err => {
      defer.reject(err);
    });
    return defer.promise;
  };

  afiliacaoOptions = (user, entidadesProprietarias) => {
    return {
      size: 'lg',
      template: require('./main/ep.dialog.html'),
      controller: ['$scope', ($scope) => {
        $scope.user = user;
        $scope.entidadesProprietarias = entidadesProprietarias;
        $scope.auxEntidadesProprietarias = {
          selected: [],
          infiniteScroll: {numToAdd: 20, currentItems: 20}
        };
        // Cycle through ativoPartesCorpo and fill auxPartesCorpo with entries that come from relation
        $scope.user.funcionariosEntidadesProprietarias.forEach(p => {
          $scope.auxEntidadesProprietarias.selected.push(p.entidadeProprietaria);
        });

        // Infinite Scroll magic
        $scope.addMoreItems = (infiniteScroll) => {
          infiniteScroll.currentItems += infiniteScroll.numToAdd;
        };

        $scope.ok = () => {
          $scope.$close($scope);
        };

        $scope.cancel = () => $scope.$dismiss('cancel');
      }]
    };
  };
}

AdministrationService.$inject = ['Rolemap', 'Role', 'Funcionario', 'Group', 'Rolegroup', '$q'];
