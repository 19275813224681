import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid';
const DIalogObs = ({ initial, onClose, ...props }) => {
    const [val, setVal] = useState(initial);
    return (<Dialog {...props}>
      <DialogTitle>Observações</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid lg={12} md={12} xs={12}>
            <TextField size='small' defaultValue={initial} onChange={e => {
            setVal(e.target.value);
        }} label="Observações" fullWidth/>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button type='button' onClick={() => onClose(undefined)}>
          Cancelar
        </Button>
        <Button onClick={() => {
            onClose(val);
        }} variant='contained'>
          OK
        </Button>
      </DialogActions>
    </Dialog>);
};
export default DIalogObs;
