export function routes($stateProvider) {
  $stateProvider
    .state('app.pedidos', {
      url: '/pedidos',
      abstract: true,
      template: '<div ui-view></div>'
    })
    .state('app.pedidos.list', {
      url: '?{page:int}&{items:int}&{state:int}&order&sort&filter',
      title: 'Gestão de Pedidos',
      template: require('./directory/view.html'),
      controller: 'DirectoryPedidosIEController',
      role: 'pedidoIeList',
      controllerAs: 'vm',
      params: {
        state: {
          value: 1,
          dynamic: true
        },
        page: {
          value: 1,
          dynamic: true
        },
        items: {
          value: 20,
          dynamic: true
        },
        order: {
          value: "id",
          dynamic: true
        },
        sort: {
          value: "desc",
          dynamic: true
        },
        filter: {
          dynamic: true
        }
      }
    })
    .state('app.pedidos.check', {
      url: '/check',
      title: 'Gestão de Pedidos',
      template: require('./check/check.view.html'),
      controller: 'CheckController',
      controllerAs: 'vm',
      role: 'pedidosCheckIE'
    })
    .state('app.pedidos.details', {
      url: '/{id}',
      title: 'Visualizar Pedido',
      template: require('./details/details.view.html'),
      controller: 'DetailPedidosController',
      role: 'pedidoIeList',
      controllerAs: 'vm'
    })
}

routes.$inject = ['$stateProvider'];
