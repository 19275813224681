import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText, Box } from '@mui/material';
export function DialogResultado({ motivos, erros, onClose, ...props }) {
    const handleCancel = () => {
        onClose?.();
    };
    return (<Dialog {...props} maxWidth={'lg'} fullWidth>
      <DialogTitle>{erros.parametros.length > 0 ? 'Parametros' : 'Ensaios'}</DialogTitle>
      <DialogContent>
        <List dense>
          {motivos && motivos.parametros.length > 0 ? (motivos.parametros.map((p, index) => {
            if (p.resultado) {
                return (<React.Fragment key={index}>
                    <ListItem>
                      <ListItemText primary={p.observacoes}/>
                    </ListItem>
                    {erros.parametros.map((e, eIndex) => (<ListItem key={eIndex}>
                        <ListItemText secondary={`${e.codigo}: ${e.designacao}`}/>
                      </ListItem>))}
                  </React.Fragment>);
            }
        })) : (<Box></Box>)}
        </List>
        <List dense>
          {motivos && motivos.ensaios.length > 0 ? (motivos.ensaios.map((e, index) => {
            if (e.resultado) {
                const formattedValidation = e.aValidar.replace(/!=/g, '≠').replace(/>=/g, '≥').replace(/<=/g, '≤');
                return (<React.Fragment key={index}>
                    <ListItem>
                      <ListItemText primary={e.observacoes} secondary={formattedValidation}/>
                    </ListItem>
                  </React.Fragment>);
            }
            return null;
        })) : (<Box></Box>)}
        </List>
      </DialogContent>
      <DialogActions>
        <Button type='button' onClick={handleCancel}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>);
}
