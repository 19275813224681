export function routes($stateProvider) {
  $stateProvider
    .state('app.elv.topicos', {
      url: '/topicos',
      abstract: true,
      template: '<div ui-view></div>'
    })
    .state('app.elv.topicos.list', {
      url: '/',
      title: 'Gestão de Tópicos',
      template: require('./directory/directory.view.html'),
      controller: 'DirectoryTopicosController',
      controllerAs: 'vm',
      role: 'elvListarTopicosVerificacao'
    })
}

routes.$inject = ['$stateProvider'];
