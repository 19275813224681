export default class EditNewsController {
  constructor($rootScope, $state, $stateParams, Noticia) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.title = $state.current.title;
    this.id = $stateParams.id;
    this.Noticia = Noticia;
    this.n = {};
    this.msg = "";
    this.button = "Editar";

    this.getNewsById();
    }

  getNewsById = () => {
    this.Noticia.findOne({
      filter: {
        where: {
          id: this.id
        }
      }
    }).$promise
      .then((result) => {
        result.active = !!result.active;
        this.n = result;
      })
      .catch((err) => {
//TODO:
      });
  };

  addNoticia = () => {
    this.n.$save();
    this.$state.go('app.noticias.list');
  };

  hasMsg = () => {
    return this.msg.length > 0;
  }
}

EditNewsController.$inject = ['$scope', '$state', '$stateParams', 'Noticia'];
