import React, { Fragment } from "react";
import Grid from "@mui/material/Grid";
import { Box, Typography } from "@mui/material";
export default function DetailsBox({ title, entryTitleSpace = 6, entryContentSpace = 6, emptyDetails = false, emptyMessage = undefined, action = null, entries }) {
    return (<Grid container>
      <Grid item xs={6}>
        <Typography variant="h6" sx={{ color: "black", fontWeight: "bold", mb: 2 }}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        {action &&
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {Array.isArray(action) ? (action.map((a, index) => (<Box key={index} sx={{ ml: 1 }}>
                {a}
              </Box>))) : (<Box sx={{ ml: 1 }}>
                {action}
              </Box>)}
          </Box>}
      </Grid>
      {!emptyDetails && entries && entries.map((item, index) => (<Fragment key={index}>
          <Grid item xs={entryTitleSpace}>
            <Typography variant="subtitle1" sx={{ color: "black", fontWeight: "bold" }}>
              {item.title}
            </Typography>
          </Grid>
          <Grid item xs={entryContentSpace}>
            {typeof item.content === 'string' ? (<Typography sx={{ color: "black" }}>
                {item.content}
              </Typography>) : (item.content)}
          </Grid>
        </Fragment>))}
      {emptyDetails &&
            <Typography sx={{ color: "black" }}>
          {emptyMessage}
        </Typography>}
    </Grid>);
}
