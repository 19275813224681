import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
const DialogEditRelatorio = ({ open, onClose, coreListasRelatorio }) => {
    return (<form>
      <Dialog open={open} maxWidth={'md'} fullWidth>
        <DialogTitle>Existe um relatório pendente</DialogTitle>
        <DialogContent></DialogContent>

        <DialogActions>
          <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2}>
            <Button type='button' onClick={() => onClose(undefined)}>
              Cancelar
            </Button>
            <Stack spacing={2} direction='row'>
              <Button onClick={() => onClose('edit')} variant='contained'>
                Editar rascunho
              </Button>
              {/* <Button onClick={() => onClose('substituir', coreListasRelatorio)} variant='contained'>
          Substituir relatório
        </Button> */}
            </Stack>
          </Stack>
        </DialogActions>
      </Dialog>
    </form>);
};
export default DialogEditRelatorio;
