import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText, Box, Typography } from '@mui/material';
export function DialogRequired({ parametros, ensaios, realizada, motivo, onClose, ...props }) {
    const handleClose = () => {
        onClose();
    };
    return (<Dialog {...props} maxWidth={'lg'} fullWidth>
      <DialogTitle>Existem campos por preencher.</DialogTitle>
      <DialogContent>
        {!realizada && motivo == undefined ? (<Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>
            Se a intervenção não foi realizada, tem de escolher um motivo
          </Typography>) : (<Box></Box>)}
        {parametros.length > 0 ? (<Box>
            <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>
              Parâmetros por preencher
            </Typography>
            <List dense>
              {parametros.map(param => (<ListItem key={param.codigo}>
                  <ListItemText primary={param.codigo + ': ' + param.designacao}/>
                </ListItem>))}
            </List>
          </Box>) : (<Box></Box>)}
        {ensaios.length > 0 ? (<Box>
            <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>
              Ensaios por preencher
            </Typography>
            <List dense>
              {ensaios.map(e => (<ListItem key={e.codigo}>
                  <ListItemText primary={e.codigo + ': ' + e.designacao}/>
                </ListItem>))}
            </List>
          </Box>) : (<Box></Box>)}
      </DialogContent>
      <DialogActions>
        <Button type='button' variant='contained' onClick={handleClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>);
}
