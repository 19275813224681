import { routes } from './routes'

import RhEventController from './events/controller';
import RhEventService from './events/service';

export default angular.module('app.rh', [])
  .config(routes)
  .service('RhEventService', RhEventService)
  .controller('RhEventController', RhEventController)
  .directive('focusMe', function () {
    return {
      scope: { trigger: '=focusIn' },
      link: function (scope, element) {
        scope.$watch('trigger', function (value) {
          if (value === true) {
            element[0].focus();
            scope.trigger = false;
          }
        });
      }
    };
  })
  .name;
