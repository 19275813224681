export default class CreateNewsController {

    constructor($rootScope, $state, Noticia) {

        this.$rootScope = $rootScope;
        this.$state = $state;
        this.title = $state.current.title;
        this.Noticia = Noticia;

        this.n = {
            active: 0,
        };

        this.msg = "";


        this.button = "Adicionar";
    }

    addNoticia = () => {

        this.n.id = 0;
        this.n.descCurta = this.n.descricao;
        this.n.data = new Date();
        this.Noticia.create(this.n).$promise.then((added) => {
            this.msg = "Notícia inserida.";

        }).catch((err) => {
            this.msg = "Erro ao inserir notícia."
        });
    };

    hasMsg = () => {
        return this.msg.length > 0;
    }
}

CreateNewsController.$inject = ['$rootScope', '$state', 'Noticia'];
