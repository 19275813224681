export default class Login {
  constructor($rootScope, $state, AuthenticationService, UIService) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.Authentication = AuthenticationService;
    this.UI = UIService;
    this.email = '';
    this.password = '';
    this.error = '';
    this.waiting = false;
  }

  performLogin = () => {
    this.waiting = true;
    this.Authentication.login(this.email, this.password).then((res) => {
      this.waiting = false;
      this.$state.go('app.dashboard');
    }).catch((err) => {
      this.waiting = false;
      switch (err.data.error.code) {
        case 'LOGIN_FAILED':
          this.UI.addToast('Utilizador ou palavra-passe errados');
          break;
        case 'LOGIN_BLOCKED':
          this.UI.addToast('Utilizador bloqueado');
          break;
      }
    });
  }
}

Login.$inject = ['$rootScope', '$state', 'AuthenticationService', 'UIService'];
