export function routes($stateProvider) {
  $stateProvider
    .state('app.obler.produtos', {
      url: '/produtos',
      abstract: true
    })
    .state('app.obler.produtos.list', {
      url: '?{page:int}&{items:int}&{state:int}&order&sort&filter',
      title: 'Produtos',
      role: ['oblerVerProdutos'],
      template: require('./list/view.html'),
      controller: 'OblProdutosListController',
      controllerAs: 'vm',
      params: {
        state: {
          value: 1,
          dynamic: true
        },
        page: {
          value: 1,
          dynamic: true
        },
        items: {
          value: 20,
          dynamic: true
        },
        order: {
          value: "id",
          dynamic: true
        },
        sort: {
          value: "desc",
          dynamic: true
        },
        filter: {
          dynamic: true
        }
      }
    })
    .state('app.obler.produtos.details', {
      url: '/{id}',
      title: 'Detalhes do Produto',
      role: ['oblerVerProdutos'],
      template: require('./details/view.html'),
      controller: 'OblProdutosDetailsController',
      controllerAs: 'vm',
    });
}

routes.$inject = ['$stateProvider'];
