import angular from 'angular';
import {routes} from './lab.routes'

import Processes from "./processes";
import Interventions from "./interventions";
import Warehouse from "./warehouse";
import Entities from "./entities";
import Clients from "./clients";
import Relatorios from "./relatorios";
import InterventionService from "./interventions/interventions.service";
import LmeService from "./lme.service";

export default angular.module('app.laboratory', [Processes, Interventions, Warehouse, Entities, Clients, Relatorios])
  .config(routes)
  .service('InterventionService', InterventionService)
  .service('LmeService', LmeService)
  .directive('allowOnlyNumbers', function () {
    return {
      restrict: 'A',
      link: function (scope, elm, attrs, ctrl) {
        elm.on('keydown', function (event) {
          if (event.which == 64 || event.which == 16) {
            // to allow numbers
            return false;
          } else if (event.which >= 48 && event.which <= 57) {
            // to allow numbers
            return true;
          } else if (event.which >= 96 && event.which <= 105) {
            // to allow numpad number
            return true;
          } else if ([8, 9, 13, 27, 37, 38, 39, 40].indexOf(event.which) > -1) {
            // to allow backspace, tab, enter, escape, arrows
            return true;
          } else {
            event.preventDefault();
            // to stop others
            return false;
          }
        });
      }
    }
  })
  .name;
