import angular from 'angular';
import { routes } from './consultas.routes';

import ConsultasService from "./consultas.service";
import AcessosController from "./acessos/acessos.controller";
import AssinaturasController from "./assinaturas/assinaturas.controller";
import ChangelogController from "./changelog/changelog.controller";
import reactChangelog from './changelog/react-changelog.tsx';

export default angular.module('app.consultas', [reactChangelog])
  .config(routes)
  .controller('AcessosController', AcessosController)
  .controller('AssinaturasController', AssinaturasController)
  .controller('ChangelogController', ChangelogController)
  .service('ConsultasService', ConsultasService)
  .name;
