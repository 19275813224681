export function routes($stateProvider) {
  $stateProvider
    .state('app.elv.emie', {
      url: '/emie',
      abstract: true,
      template: '<div ui-view></div>'
    })
    .state('app.elv.emie.list', {
      url: '/',
      title: 'Gestão de EMIEs',
      template: require('./directory/directory.view.html'),
      controller: 'DirectoryEmieController',
      controllerAs: 'vm',
      params: {
        state: {
          value: 1,
          dynamic: true
        },
        page: {
          value: 1,
          dynamic: true
        },
        items: {
          value: 20,
          dynamic: true
        },
        order: {
          value: "id",
          dynamic: true
        },
        sort: {
          value: "desc",
          dynamic: true
        },
        filter: {
          dynamic: true
        }
      },
      role: 'elvListarEmies'
    })
    .state('app.elv.emie.details', {
      url: '/{id}',
      title: 'Detalhes do EMIE',
      template: require('./details/details.view.html'),
      controller: 'DetalhesEmieController',
      controllerAs: 'vm',
      role: 'elvListarEmies'
    });
}
routes.$inject = ['$stateProvider'];
