import { Box, Breadcrumbs, Link, MenuItem, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Table } from '../../../interface/components';
const ListPecasPendentes = ({ $state, OblerPendentesService, UIService }) => {
    const loadParams = () => {
        const sortBy = $state.params.sortBy;
        if (sortBy == undefined) {
            return [];
        }
        const o = [];
        sortBy.forEach(r => {
            const s = r.split(':');
            o.push({ id: s[0], desc: s[1] == 'DESC' });
        });
        return o;
    };
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [refetching, setRefetching] = useState(false);
    const [options, setOptions] = useState({
        filter: [],
        sort: []
    });
    const [pagination, setPagination] = useState({
        pageIndex: $state.params.page - 1 || 0,
        pageSize: $state.params.limit || 20
    });
    const [filter, setFilter] = useState([]);
    const [sort, setSort] = useState(() => loadParams());
    const validate = async (row) => {
        UIService.showConfirm(`Confirma receção da peça ${row.designacao} referente ao processo ${row.Processo.Ordemintervencao.numeroProcesso}? Esta ação não pode ser anulada!`).then(r => {
            try {
                OblerPendentesService.validate(row.id);
            }
            catch (e) {
                console.log();
            }
            setRefetching(true);
            fetchData();
        });
    };
    const getType = (type) => {
        switch (type) {
            case 'contains':
                return '$ilike';
            case 'startsWith':
                return '$sw';
            case 'notEquals':
                return '$not';
            case 'empty':
                return '$null';
            case 'notEmpty':
                return '$notNull';
            case 'greaterThan':
                return '$gt';
            case 'greaterThanOrEqualTo':
                return '$gte';
            case 'lessThan':
                return '$lt';
            case 'lessThanOrEqualTo':
                return '$lte';
            default:
                return '$eq';
        }
    };
    const initialFetch = async () => {
        if (loading) {
            const opt = await OblerPendentesService.options();
            setOptions(opt);
            setLoading(false);
        }
    };
    const fetchData = async () => {
        try {
            let filters = {};
            filter.forEach(r => {
                filters[r.id] = `${getType(r.type)}:${r.value}`;
            });
            let sorter = [];
            sort.forEach(r => {
                sorter.push(`${r.id}:${r.desc ? 'DESC' : 'ASC'}`);
            });
            const aux = await OblerPendentesService.findAll({
                limit: pagination.pageSize,
                page: pagination.pageIndex + 1,
                filter: filters,
                sortBy: sorter
            });
            $state.go($state.current.name, {
                limit: pagination.pageSize,
                page: pagination.pageIndex + 1,
                sortBy: sorter,
                filter: Object.keys(filter).length > 0 ? JSON.stringify(filters) : undefined
            }, {
                notify: false,
                reload: false,
                location: 'replace',
                inherit: true
            });
            // Update URL
            setData(aux);
        }
        catch (e) { }
        setRefetching(false);
    };
    useEffect(() => {
        initialFetch();
        if (!refetching) {
            setRefetching(true);
            fetchData();
        }
    }, [setPagination, filter, sort]);
    return (<Box>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Typography variant='h5'>Listagem</Typography>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link underline='none' color='inherit'>
            Obler
          </Link>
          <Link underline='hover' color='text.primary' aria-current='page'>
            Peças pendentes
          </Link>
        </Breadcrumbs>
      </Box>

      <Box>
        <Table onDoubleClick={row => {
            $state.go('app.obler.orcamentos.details', {
                id: row.processoId
            });
        }} storageKey={$state.current.name} data={data} withRowStyle={row => {
            if (row.rececionado == 1 || row.rececionadoId != null || row.prazo == null) {
                return {};
            }
            let isWithin = moment(row.dataRequisicao).add(row.prazo, 'days').diff(moment(), 'days');
            const isPending = (row.rececionado == null || row.rececionadoId == null) && isWithin <= 0;
            if (isPending) {
                return {
                    sx: {
                        backgroundColor: '#B00020',
                        '& td': {
                            color: '#FFF'
                        }
                    }
                };
            }
            return {};
        }} columns={[
            {
                accessorKey: 'id',
                header: '#',
                size: 50
            },
            {
                accessorKey: 'Processo.Ordemintervencao.numeroProcesso',
                header: 'Processo',
                size: 160
            },
            {
                accessorKey: 'Processo.Ordemintervencao.parceiro',
                header: 'Parceiro',
                size: 160
            },
            {
                accessorKey: 'compra',
                header: 'Cód. Compra',
                size: 160
            },
            {
                accessorKey: 'codigo',
                header: 'Cod. Artigo'
            },
            {
                accessorKey: 'designacao',
                header: 'Designação'
            },
            {
                accessorKey: 'fornecedor',
                header: 'Fornecedor'
            },
            {
                accessorKey: 'quantidade',
                header: 'Quantidade'
            },
            {
                accessorKey: 'prazo',
                header: 'Prazo (dias)',
                Cell: ({ cell, row }) => {
                    const value = cell.getValue();
                    if (value != undefined) {
                        const isDone = row.original.rececionado == 1 || row.original.rececionadoPor != null;
                        const req = row.original.dataRequisicao;
                        const prazo = row.original.prazo;
                        if (isDone)
                            return '-';
                        if (req != null && prazo != null) {
                            let isWithin = moment(req).add(prazo, 'days').diff(moment(), 'days');
                            if (isWithin > 0) {
                                return (<Tooltip title={`Dentro do prazo estipulado (${isWithin} dias)`}>
                          <Box>{value} (Dentro do prazo)</Box>
                        </Tooltip>);
                            }
                            else {
                                return (<Tooltip title='Fora do prazo estipulado'>
                          <Box>{value} (Fora do prazo)</Box>
                        </Tooltip>);
                            }
                        }
                        return '-';
                    }
                    return '-';
                }
            },
            {
                accessorKey: 'preco',
                header: 'Preço (€)',
                type: ''
            },
            {
                accessorKey: 'dataRequisicao',
                header: 'Data de requisição',
                Cell: ({ cell }) => {
                    const value = cell.getValue();
                    if (value != undefined)
                        return moment(value).format('DD-MM-YYYY [às] HH:mm');
                    return '-';
                }
            },
            {
                accessorKey: 'requisitanteId',
                header: 'Requisitante',
                filterSelectOptions: [
                    { label: 'António Gomes', value: 114 },
                    { label: 'Tiago Ribeiro', value: 112 }
                ],
                Cell: ({ row }) => {
                    return row.original.Requisitante?.name;
                }
            },
            {
                accessorKey: 'rececionado',
                header: 'Rececionado?',
                Cell: ({ row }) => {
                    return row.original.rececionado == 1 ? 'Sim' : 'Não';
                }
            },
            {
                accessorKey: 'dataRececao',
                header: 'Data de receção',
                Cell: ({ cell }) => {
                    const value = cell.getValue();
                    if (value != undefined)
                        return moment(value).format('DD-MM-YYYY [às] HH:mm');
                    return '-';
                }
            },
            {
                accessorKey: 'rececionadoId',
                header: 'Rececionado Por',
                filterSelectOptions: [
                    { label: 'António Gomes', value: 114 },
                    { label: 'Tiago Ribeiro', value: 112 }
                ],
                Cell: ({ row }) => {
                    return row.original.RececionadoPor?.name;
                }
            }
        ]} initial={{
            sort: sort,
            filter: filter
        }} cellActionItems={({ row }) => {
            const items = [];
            if (row.original.rececionadoId == null) {
                items.push(<MenuItem key={`row.edit.${row.id}`} onClick={() => validate(row.original)}>
                  Validar
                </MenuItem>);
            }
            items.push(<MenuItem key={`row.edit.${row.id}`} onClick={() => $state.go('app.obler.orcamentos.details', { id: row.id })}>
                Ir para processo
              </MenuItem>);
            return items;
        }} isLoading={refetching} options={options} onPaginate={setPagination} onFilter={setFilter} onSort={setSort}></Table>
      </Box>
    </Box>);
};
export default ListPecasPendentes;
