import { routes } from './routes';

import OblOrdemIntervencao from './ordensintervencao/index';
import OblViaturas from './viaturas/index';
import OblArmazens from './armazem/index.js';
import OblProdutos from './produtos/index.js';
import OblRevamp from './revamp';

import OblCalendarizacaoController from './calendarizacao/controller';
import OblClientesListController from './clientes/list/controller';
import OblClientesDetailsController from './clientes/details/controller';
import OblProcessosListController from './processos/list/controller';

export default angular
  .module('app.obler', [OblOrdemIntervencao, OblViaturas, OblArmazens, OblProdutos, OblRevamp])
  .config(routes)
  .controller('OblClientesListController', OblClientesListController)
  .controller('OblCalendarizacaoController', OblCalendarizacaoController)
  .controller('OblClientesDetailsController', OblClientesDetailsController)
  .controller('OblProcessosListController', OblProcessosListController).name;
