export function routes($stateProvider) {
  $stateProvider
    .state('app.ativos.entidadesproprietarias', {
      url: '/entidadesproprietarias',
      abstract: true,
      template: '<div ui-view></div>'
    })
    .state('app.ativos.entidadesproprietarias.list', {
      url: '/',
      controller: 'AtvEntidadesProprietariasListController',
      controllerAs: 'vm',
      template: require('./directory/directory.view.html'),
      title: 'Entidades Proprietárias',
      role: 'atvListarEP'
    })
    .state('app.ativos.entidadesproprietarias.details', {
      url: '/{id}',
      controller: 'AtvEntidadesProprietariasDetailsController',
      controllerAs: 'vm',
      template: require('./details/details.view.html'),
      role: 'atvListarEP'
    })
    .state('app.ativos.entidadesproprietarias.propriedade', {
      url: '/{id}/aoa/{pr}',
      controller: 'AtvPropriedadeDetailsController',
      controllerAs: 'vm',
      template: require('./propriedade/details.view.html'),
      title: 'Área Organizacional',
      role: 'atvListarPropriedades'
    })
  ;
}

routes.$inject = ['$stateProvider'];
