export default class FormacaoAreaDetailsController {
  constructor(UIService, $state, FormacaoArea, FormacaoAreaCategoria) {
    this.UI = UIService;
    this.$state = $state;
    this.FormacaoArea = FormacaoArea;
    this.FormacaoAreaCategoria = FormacaoAreaCategoria;
    this.id = $state.params.id;
    this.loaded = false;
    this.loadData();
  }

  loadData = () => {
    this.loaded = false;
    this.FormacaoArea.findOne({
      filter: {
        where: {
          id: this.id,
          active: true
        },
        include: ['categorias', 'areaEstudo']
      }
    }).$promise.then(res => {
      if (res) {
        this.loaded = true;
        this.UI.setTitle(res.designacao);
        this.data = res;
      } else {

      }
    })
  };

  editDetails = () => {
    let data = angular.copy(this.data);
    this.UI.showDialog({
      size: 'lg',
      template: require('./details.dialog.html'),
      controller: ['$scope', '$dialog', 'FormacaoAreaEstudo', ($scope, $dialog, FormacaoAreaEstudo) => {

        $scope.area = {
          id: this.id,
          designacao: data.designacao,
          codigo: data.codigo,
          active: true
        };

        $scope.auxArea = {
          selected: angular.copy(this.data.areaEstudo)
        };

        FormacaoAreaEstudo.find({
          filter: {
            where: {
              active: true
            }
          }
        }).$promise.then(areas => {
          $scope.areas = areas;
        });

        $scope.ok = () => {
          $scope.area.areaEstudoId = $scope.auxArea.selected.id;
          $dialog.close($scope.area);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        }
      }]
    }).then(res => {
      if (res) {
        this.FormacaoArea.upsert(res).$promise.then(() => {
          this.loadData();
          this.UI.addToast('Área de formação atualizada com sucesso');
        }).catch(() => {
          this.UI.addToast('Ocorreu um erro a atualizar área de formação');
        });
      }
    }).catch(() => {
    });
  };

  addCategory = () => {
    this.showModalForCategory({id: 0});
  };

  editCategory = (category) => {
    this.showModalForCategory(category);
  };

  showModalForCategory = (category) => {
    this.UI.showDialog({
      size: 'lg',
      controller: ['$scope', '$dialog', ($scope, $dialog) => {

        $scope.add = category.id === 0;
        $scope.category = category;

        $scope.ok = () => {
          $dialog.close($scope.category);
        };

        $scope.cancel = () => {
          $dialog.dismiss('cancel');
        }
      }],
      template: require('./category.dialog.html')
    }).then(res => {
      if (res) {
        res.areaId = this.id;
        res.active = true;
        this.FormacaoAreaCategoria.upsert(res).$promise.then(() => {
          this.loadData();
          this.UI.addToast('Categoria registada com sucesso');
        }).catch(() => {
          this.UI.addToast('Ocorreu um erro ao registar categoria')
        });
      }
    });
  };

  removeCategory = (cat) => {
    this.UI.showConfirm('Deseja remover categoria?').then(res => {
      if (res) {
        cat.active = false;
        this.FormacaoAreaCategoria.upsert(cat).$promise.then(() => {
          this.loadData();
          this.UI.addToast('Categoria removida com sucesso');
        }).catch(() => {
          this.UI.addToast('Ocorreu um erro ao remover categoria')
        });
      }
    })
  }
}

FormacaoAreaDetailsController.$inject = ['UIService', '$state', 'FormacaoArea', 'FormacaoAreaCategoria'];
