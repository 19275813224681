import angular from "angular";
import moment from "moment";
import modalHelper from "./modalHelper";

export default angular
  .module("app.interface.dialogs", [modalHelper])
  .controller("DialogController", [
    "$scope",
    "$dialog",
    "message",
    function ($scope, $dialog, message) {
      $scope.message = message;

      $scope.ok = () => {
        $dialog.close(true);
      };

      $scope.cancel = () => {
        $dialog.dismiss("cancel");
      };
    },
  ])
  .controller("DateDialogController", [
    "$scope",
    "$dialog",
    "options",
    "helperText",
    "initialDate",
    function ($scope, $dialog, options, helperText, initialDate) {
      $scope.options = options || {
        format: "YYYY-MM-DD",
      };

      $scope.helperText = helperText;

      $scope.date = initialDate || undefined;

      $scope.ok = () => {
        $dialog.close($scope.date);
      };

      $scope.cancel = () => {
        $dialog.dismiss("cancel");
      };
    },
  ])
  .controller("DateRangeDialogController", [
    "$scope",
    "$dialog",
    "options",
    "helperText",
    function ($scope, $dialog, options, helperText) {
      $scope.options = angular.copy(options) || {
        format: "YYYY-MM-DD",
      };

      $scope.formatter = (date) => {
        return moment.utc(date).format(options.format);
      };

      $scope.helperText = helperText;

      $scope.time = {
        from: moment.utc().subtract(7, "day"),
        to: moment.utc(),
      };

      $scope.ok = () => {
        $dialog.close($scope.time);
      };

      $scope.cancel = () => {
        $dialog.dismiss("cancel");
      };
    },
  ])
  .factory("Dialog", [
    "$rootScope",
    "$modal",
    function ($rootScope, $modal) {
      return {
        waiting: () => {
          return $modal.open({
            keyboard: false,
            backdrop: "static",
            size: "sm",
            template:
              '<div style="padding: 24px 16px 16px 16px;"><pmd-spinner></pmd-spinner></div>',
          });
        },
        alert: (message) => {
          return $modal.open({
            keyboard: true,
            backdrop: "static",
            template: require("./templates/alert.html"),
            controller: "DialogController",
            resolve: {
              message: function () {
                return message;
              },
            },
          }).result;
        },
        date: (options, helperText, initialDate) => {
          return $modal.open({
            keyboard: true,
            backdrop: "static",
            template: require("./templates/date.html"),
            controller: "DateDialogController",
            resolve: {
              options: function () {
                return options;
              },
              initialDate: function () {
                return initialDate;
              },
              helperText: function () {
                return helperText;
              },
            },
          }).result;
        },
        dateRange: (options, helperText) => {
          return $modal.open({
            keyboard: true,
            backdrop: "static",
            template: require("./templates/range-picker.html"),
            controller: "DateRangeDialogController",
            resolve: {
              options: function () {
                return options;
              },
              helperText: function () {
                return helperText;
              },
            },
          }).result;
        },
        confirm: (message) => {
          return $modal.open({
            keyboard: true,
            backdrop: "static",
            template: require("./templates/confirm.html"),
            controller: "DialogController",
            resolve: {
              message: function () {
                return message;
              },
            },
          }).result;
        },
        message: (messages, text) => {
          if (text == undefined || text == "") {
            text = "Corrija os seguintes erros no formulário:";
          }
          return $modal.open({
            keyboard: true,
            backdrop: "static",
            template: require("./templates/message.html"),
            controller: "DialogController",
            resolve: {
              message: function () {
                return messages;
              },
              text: function () {
                return text;
              },
            },
          }).result;
        },
        dialog: (options) => {
          options.backdrop = "static";
          options.size = options.size || "lg";
          return $modal.open(options).result;
        },
      };
    },
  ])
  .run([
    "$rootScope",
    ($rootScope) => {
      $rootScope.uiModules = $rootScope.uiModules || [];
      let properties = {
        name: "Dialogs",
        description: "Dialog & Bottom Sheets",
        version: "1.0.2",
      };
      if ($rootScope.uiModules.indexOf(properties) === -1) {
        $rootScope.uiModules.push(properties);
      }
    },
  ]).name;
