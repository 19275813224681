export default class ElvAdminFileUploaderController {
  constructor($dialog, FileUploader, UIService, type) {
    this.dialog = $dialog;
    this.UI = UIService;
    this.type = type;
    this.progress = 0;
    this.uploading = false;
    this.emptyFile = true;

    this.item = null;


    switch(this.type) {
      case 'C1C2':
        this.title = 'Carregar Cláusulas C1+C2';
        break;
      case 'C3':
        this.title = 'Carregar Cláusulas C3';
        break;
      case 'C2*':
        this.title = 'Carregar Cláusulas C2*';
        break;
      case 'Condominios':
        this.title = 'Carregar Condomínios';
        break;
    }

    // Setup file uploader
    this.uploader = new FileUploader({
      url: '/api/Upload/elv/upload',
      queueLimit: 1
    });

    // Force queue limit = 1
    this.uploader.onAfterAddingFile = (item) => {
      this.emptyFile = false;
      this.item = item;
      if (this.uploader.queue.length > 1) {
        this.uploader.queue.splice(0, this.uploader.queue.length - 1);
      }
    };

    this.uploader.onWhenAddingFileFailed = () => {
      this.UI.addToast('Erro ao adicionar ficheiro');
      // this.cancel();
    };
  }

  disabledOk = () => {
    return this.uploader.queue.length === 0;
  };

  ok = () => {
    this.dialog.close({item: this.item});
  };

  clearFile = () => {
    this.emptyFile = true;
    this.item = null;
    if (this.uploader.queue && this.uploader.queue.length > 0)
      this.uploader.clearQueue();
  };

  cancel = () => {
    this.dialog.dismiss('cancel');
  };
}

ElvAdminFileUploaderController.$inject = ['$dialog', 'FileUploader', 'UIService', 'type'];
