import React from 'react';
import { Typography, CircularProgress, Dialog, DialogContent, DialogTitle } from '@mui/material';
export function WaitDialog({ ...props }) {
    return (<Dialog {...props} fullWidth maxWidth={'xs'}>
      <DialogTitle></DialogTitle>
      <DialogContent sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Typography variant='body1'>{props.title}</Typography>
        <CircularProgress />
      </DialogContent>
    </Dialog>);
}
