export function routes($stateProvider) {
  $stateProvider
    .state('app.ativos.ativos', {
      abstract: true,
      template: '<div ui-view></div>'
    })
    .state('app.ativos.ativos.list', {
      url: '?{page:int}&{items:int}&{state:int}&order&sort&filter',
      title: 'Gestão de Ativos',
      template: require('./directory/directory.view.html'),
      controller: 'AtvAtivosListController',
      controllerAs: 'vm',
      role: 'atvListarAtivos',
      params: {
        state: {
          value: 1,
          dynamic: true
        },
        page: {
          value: 1,
          dynamic: true
        },
        items: {
          value: 20,
          dynamic: true
        },
        order: {
          value: "id",
          dynamic: true
        },
        sort: {
          value: "desc",
          dynamic: true
        },
        filter: {
          dynamic: true
        }
      }
    })
    .state('app.ativos.ativos.details', {
      url: '/{id}',
      title: 'Ativo',
      controller: 'AtvAtivosDetailsController',
      controllerAs: 'vm',
      template: require('./details/details.view.html'),
      role: 'atvListarAtivos'
    })
    .state('app.ativos.ativos.osmeusativos', {
      url: '/meusativos',
      title: 'Os Meus Ativos',
      controller: 'AtvAtivosOsMeusAtivosController',
      controllerAs: 'vm',
      template: require('./meusativos/details.view.html'),
      role: '$internal'
    })
    .state('app.ativos.ativos.new', {
      url: '/novo',
      title: 'Adicionar Ativo',
      template: require('./new/new.view.html'),
      controller: 'AtvCreateAtivoController',
      controllerAs: 'vm',
      role: ['atvCriarAtivos'],
    })
    .state('app.ativos.ativos.new.1', {
      template: require('./new/details.form.html')
    })
    .state('app.ativos.ativos.new.2', {
      template: require('./new/datas.form.html')
    })
    .state('app.ativos.ativos.new.3', {
      template: require('./new/others.form.html')
    })
  ;
}

routes.$inject = ['$stateProvider'];
