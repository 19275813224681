export function routes($stateProvider) {
  $stateProvider
    .state('app.elv.normas', {
      url: '/normas',
      abstract: true,
      template: '<div ui-view></div>'
    })
    .state('app.elv.normas.list', {
      url: '/',
      title: 'Gestão de Normas',
      template: require('./directory/directory.view.html'),
      controller: 'DirectoryNormasController',
      controllerAs: 'vm',
      role: 'elvListarNormas'
    })
}

routes.$inject = ['$stateProvider'];
