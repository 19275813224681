export default class FunCalendarizacaoController {
  constructor($state, FunAgendamento, FunProcesso, FunTecnico, Avatar, UIService, AuthenticationService, AuthorizationService) {
    this.$state = $state;
    this.FunAgendamento = FunAgendamento;
    this.FunProcesso = FunProcesso;
    this.FunTecnico = FunTecnico;
    this.Auth = AuthenticationService;
    this.AuthorizationService = AuthorizationService;
    this.Avatar = Avatar;
    this.UI = UIService;
    this.search = "";
    this.clients = [];
    this.selected = 0;
    this.select = false;

    this.completed = $state.params.completed == 1;
    this.open = $state.params.open == 1;

    this.calendarLoading = true;

    this.calendarOptions = {
      dayClick: (start, end, jsEvent, view) => {
        // this.addAgendamento(start);
      },
      eventRender: (event, element) => {
        if (this.api.view.name == "listMonth") {
          let target = angular.element(element[0].querySelector('.fc-list-item-title'));
          let child = document.createElement("span");
          let division = document.createTextNode(" - ");
          let text = document.createTextNode(event.user);
          child.appendChild(division);
          child.appendChild(text);
          target.append(child);
        } else if (this.api.view.name == "month") {
          let target = angular.element(element[0].querySelector('.fc-title'));
          let child = document.createElement("span");
          let division = document.createTextNode(" - ");
          let text = document.createTextNode(event.user);
          child.appendChild(division);
          child.appendChild(text);
          target.append(child);
        } else {
          let target = angular.element(element[0].querySelector('.fc-time'));
          let child = document.createElement("span");
          let division = document.createTextNode(" - ");
          let text = document.createTextNode(event.user);
          if (!event.isAllDay) {
            child.appendChild(division);
            child.appendChild(text);
            target.append(child);
          } else {
            target[0].innerHTML = `<span>${event.user}</span>`
            target[0].innerText = event.user;
          }
        }
      },
      eventClick: (event) => {
        this.$state.go(`app.funciona.processos.details`, { id: event.id });
      },
      customButtons: {
        filter: {
          text: 'Filtrar',
          click: () => {
            this.$state.go('app.funciona.calendario', {
              funcionario: this.funcionario == undefined ? undefined : this.funcionario.id,
              completed: +this.completed,
              open: +this.open
            }, {
              notify: false,
              reload: false,
              location: 'replace',
              inherit: true
            });
            this.getAgendamentos();
          }
        },
        clear: {
          text: 'LIMPAR',
          click: () => {
            this.$state.go('app.funciona.calendario', {
              funcionario: undefined,
              completed: 1,
              open: 1
            }, {
              notify: false,
              reload: false,
              location: 'replace',
              inherit: true
            });
            this.getAgendamentos();
          }
        }
      },
      timeFormat: 'HH:mm',
      defaultView: 'agendaWeek',
      locale: 'pt',
      themeSystem: 'bootstrap4',
      header: {
        left: 'prev,next today filter clear',
        center: 'title',
        right: 'month,agendaWeek,agendaDay,listMonth'
      },
      navLinks: true,
      defaultTimedEventDuration: '01:00:00',
      allDaySlot: false,
      displayEventEnd: true,
      minTime: "08:00:00",
      maxTime: "22:00:00",
      height: 'auto',
      slotDuration: "00:15:00",
      slotLabelFormat: "HH:mm",
      views: {
        agendaWeek: {
          columnHeaderFormat: "DD/MM"
        }
      },
      firstDay: 1,
      nowIndicator: true,
      weekends: true,
      hiddenDays: [0],
      weekNumberTitle: 'Sem.',
      eventLimitText: 'agd.',
      noEventsMessage: 'Sem agendamentos a apresentar.',
      weekNumbers: true,
      buttonText: {
        today: 'Hoje',
        month: 'Mensal',
        week: 'Semanal',
        day: 'Diário',
        list: 'Lista'
      }
    };
    this.agendamentos = [];
    this.calendarEvents = [];

    this.getAgendamentos();
  };

  getAgendamentos = () => {
    let whereTecnico = {and: [{active: 1}]};
    let isTecnico = false;
    if (!this.AuthorizationService.canPerform('funcionaVerTodosProcessos')) {
      if (this.AuthorizationService.canPerform('funcionaVerProcessos')) {
        isTecnico = true;
        whereTecnico.and.push({funcionarioId: this.Auth.getId()});
      } else {
        this.UI.addToast("Não tem permissões para ver agendamentos.");
        this.calendarEvents = [];
        this.calendarLoading = false;
        return;
      }
    }

    // Get Tecnico(s) for view
    this.FunTecnico.find({
      filter: {
        where: whereTecnico,
        include: {
          relation: 'funcionario'
        }
      }
    }).$promise.then(f => {
      this.funcionarios = [];
      f.forEach(r => {
        this.funcionarios.push(r.funcionario);
      });
      this.funcionarios = this.funcionarios.sort((a, b) => a.name.localeCompare(b.name));

      // Where for Agendamento. If Tecnico only, no need to get everything
      let whereAgendamento = {
        active: true,
        data: {
          gte: moment().subtract(1, 'year').startOf('d') // Only show agendamentos of the last year otherwise it's too much data
        }
      };
      if (this.$state.params.funcionario !== undefined || isTecnico) {
        if (isTecnico) {
          this.funcionario = this.funcionarios[0];
        } else {
          this.funcionario = this.funcionarios.find(r => r.id === this.$state.params.funcionario);
        }
      }

      // If we are looking for a specific one only
      if (this.funcionario) {
        whereAgendamento.tecnicoId = this.funcionario.id;
      } else {
        whereAgendamento.tecnicoId = null; // So it finds nothing if nothing is selected / used
      }
      this.FunAgendamento.find({
        filter: {
          where: whereAgendamento,
          include: ['Funcionario',
            {
              relation: 'Processo',
            }]
        }
      }).$promise.then(agendamentos => {
        if (this.funcionario) {
          agendamentos = agendamentos.filter(x => x.tecnicoId === this.funcionario.id);
        }
        this.agendamentos = agendamentos;
        let data = [];
        this.agendamentos.forEach(event => {
          if ((this.completed && event.Processo.estadoId === 10) || ((this.open && event.Processo.estadoId !== 10))) {
            let date = moment(`${moment(event.data).format("YYYY/MM/DD")} ${event.hora}`, 'YYYY/MM/DD HH:mm');
            let title = `${event.Processo.numeroProcesso}\n${event.Processo.distritoCliente}`;
            data.push({
              id: event.id,
              start: date,
              user: event.Funcionario.name,
              allDay: false,
              extra: event.Processo,
              end: moment(date).add(event.duracao, 'minute'),
              title: title,
              color: event.Processo.estadoId === 10 ? '#33B679': '#E57368' // this.Avatar.getColor(this.Avatar.getInitials(event.Funcionario.name))
            });
          }
        });
        this.calendarEvents = data;
        this.calendarLoading = false;
      });
    });
  };

  showAgendamentoDetail = (calEvent, jsEvent, view) => {
    //let agendamento = this.agendamentos.find(r => r.id == calEvent.id);
  };

}

FunCalendarizacaoController.$inject = ['$state', 'FunAgendamento', 'FunProcesso', 'FunTecnico', 'Avatar', 'UIService', 'AuthenticationService', 'AuthorizationService'];

