import angular from "angular";
import { react2angular } from 'react2angular';
import React from "react";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Avatar, Box, Card, CardContent, CardHeader, CircularProgress, List, ListItemButton, ListItemText } from "@mui/material";
import { red } from "@mui/material/colors";
class ChangelogPage extends React.Component {
    modules = [];
    versions = [];
    moduleVersions = [];
    version;
    loaded = false;
    loadModules = async () => {
        let response = await fetch("https://cc.streamline.pt/api/applications/findOne?filter=%7B%22where%22:%7B%22id%22:%2260a288ed33320700119606f9%22%7D,%22include%22:%5B%22modules%22,%22client%22,%7B%22relation%22:%22versions%22,%22scope%22:%7B%22order%22:%22version+DESC%22,%22include%22:%22module%22%7D%7D%5D%7D");
        if (response.status === 200) {
            let data = await response.json();
            this.modules = data.modules;
            this.modules.map(r => {
                let latest = data.versions.find(v => v.moduleId == r.id);
                r.version = latest;
            });
            this.modules = this.modules.filter(r => r.version != undefined);
            this.version = data.versions[0];
            this.versions = data.versions;
            this.pickModule(this.version.moduleId);
        }
        else {
        }
        this.loaded = true;
        this.setState({});
    };
    componentDidMount() {
        this.loadModules();
    }
    pickModule = (id) => {
        let module = this.modules.find(r => r.id === id);
        this.moduleVersions = this.versions.filter(r => r.moduleId === id);
        this.moduleVersions = this.moduleVersions.slice(0, 7);
        this.setState({});
    };
    versionFrom = changelog => {
        let parts = changelog.version.match(/.{1,3}/g) || [];
        parts = parts.map((r) => Number(r));
        return parts.join(".");
    };
    pickVersion = changelog => {
        this.version = changelog;
        this.setState({});
    };
    render = () => {
        return (<div className="mt-2">
        {this.loaded ?
                <div>
            <Grid container spacing={2} sx={{ mb: 3 }}>
              {this.modules.map(r => {
                        return <Grid xl={2} sm={4} xs={12}>
                  <Card>
                    <CardHeader avatar={<Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                          {r.name}
                        </Avatar>} title={r.value} subheader={this.versionFrom(r.version)}/>
                  </Card>
                </Grid>;
                    })}
            </Grid>
            <Grid container spacing={1}>
              <Grid xl={10} sm={8} xs={12}>
                <Grid>
                  <Card>
                    <CardHeader title="Novas funcionalidades"/>
                    <CardContent>
                      <div dangerouslySetInnerHTML={{ __html: this.version.added }}>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid>
                  <Card>
                    <CardHeader title="Melhorias"/>
                    <CardContent>
                      <div dangerouslySetInnerHTML={{ __html: this.version.improvements }}>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid>
                  <Card>
                    <CardHeader title="Correção de falhas"/>
                    <CardContent>
                      <div dangerouslySetInnerHTML={{ __html: this.version.bugfixes }}>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              <Grid xl={2} sm={4} xs={12}>
                <Grid>
                  <Card>
                    <CardHeader title="Histórico de versões" subheader="Carregar versões anteriores"/>
                    <List component="nav" aria-label="main mailbox folders">
                      {this.moduleVersions.map(r => {
                        return (<ListItemButton key={r.id} onClick={(event) => this.pickVersion(r)} selected={this.version.version == r.version}>
                            <ListItemText primary={this.versionFrom(r)}/>
                          </ListItemButton>);
                    })}

                    </List>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </div>
                : <Box sx={{ display: 'flex' }}>
            <CircularProgress />
          </Box>}
      </div>);
    };
}
export default angular
    .module('app.react.changelog', [])
    .component('changelogPage', react2angular(ChangelogPage, []))
    .name;
