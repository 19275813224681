import angular from 'angular';

import {routes} from "./condominio.routes";
import DirectoryCondominioController from "./directory/directory.controller";
import DetalhesCondominioController from "./details/details.controller";

export default angular.module('app.elv.condominios', [])
  .config(routes)
  .controller('DirectoryCondominioController', DirectoryCondominioController)
  .controller('DetalhesCondominioController', DetalhesCondominioController)
  .name;
