import angular from 'angular';
import {routes} from "./processos.routes";
import DirectoryPedidosElevadoresController from "./directory/directory.controller";
import DetailPedidosElevadoresController from "./details/details.controller";
import CreatePedidoController from "./new/new.controller";
import ElvElevadorCreationController from "./new/elevador.dialog.controller";
import ELVProcessFileUploaderController from "./details/files.dialog.controller";
import ElvProcessoService from "./processos.service";

require('ui-select/dist/select.min.css');

export default angular.module('app.elv.processos', [])
  .config(routes)
  .controller('DirectoryPedidosElevadoresController', DirectoryPedidosElevadoresController)
  .controller('DetailPedidosElevadoresController', DetailPedidosElevadoresController)
  .controller('CreatePedidoController', CreatePedidoController)
  .controller('ElvElevadorCreationController', ElvElevadorCreationController)
  .controller('ELVProcessFileUploaderController', ELVProcessFileUploaderController)
  .service('ElvProcessoService', ElvProcessoService)
  .name;
