import angular from 'angular';

import {routes} from "./relatorios.routes";
import DirectoryRelatorioController from "./directory/directory.controller";
import DetalhesRelatorioController from "./details/details.controller";

export default angular.module('app.elv.relatorios', [])
  .config(routes)
  .controller('DirectoryRelatorioController', DirectoryRelatorioController)
  .controller('DetalhesRelatorioController', DetalhesRelatorioController)
  .directive('asNumber', ['$locale', function ($locale) {
    return {
      restrict: 'A',
      require: '?ngModel',
      compile: function (tElement, tAttrs) {
        if (tElement[0].nodeName !== 'INPUT') {
          throw('Error. asNumber directive must be used inside an <input> element.');
        }
        tElement.attr('pattern', '[0-9]*');
        return function (scope, element, attrs, ngModelCtrl, undefined) {
          if (!ngModelCtrl) {
            return;
          }
          var step, newValue;
          var maxAttr = (attrs.hasOwnProperty('max') && attrs.max !== '') ? parseFloat(attrs.max) : false,
            minAttr = (attrs.hasOwnProperty('min') && attrs.min !== '') ? parseFloat(attrs.min) : false,
            stepAttr = (attrs.hasOwnProperty('step') && attrs.step !== '') ? parseFloat(attrs.step) : 1,
            decPlaces = stepAttr.toString().includes(".") ? stepAttr.toString().split(".")[1].length : 0; // Number of decimal places

          element.on('keydown', function (event) {
            if (event.keyCode === 64 || event.keyCode === 16) {
              // to allow numbers
              return false;
            } else if (event.keyCode >= 48 && event.keyCode <= 57) {
              // to allow numbers
              return true;
            } else if (event.keyCode >= 96 && event.keyCode <= 105) {
              // to allow numpad number
              return true;
            } else if ([8, 9, 13, 27, 37, 38, 39, 40].indexOf(event.keyCode) > -1) {
              // to allow backspace, tab, enter, escape, arrows
              return true;
            } else if (event.keyCode === 38 || event.keyCode === 40) {
              event.preventDefault();
              step = (event.shiftKey) ? (stepAttr * 10) : stepAttr;
              // Arrow down
              if (event.keyCode === 40) {
                step *= -1;
              }

              newValue = (isNaN(ngModelCtrl.$modelValue)) ? step : ngModelCtrl.$modelValue + step;
              // Round it if necessary
              if (decPlaces > 0)
                newValue = Math.round(newValue * Math.pow(10, decPlaces)) / Math.pow(10, decPlaces);

              if (maxAttr !== false && newValue > maxAttr) {
                newValue = maxAttr;
              } else if (minAttr !== false && newValue < minAttr) {
                newValue = minAttr;
              }
              newValue = String(newValue);
              // if ($locale.NUMBER_FORMATS.DECIMAL_SEP === ',') {
              //   console.log("separator is virgula");
              //   newValue = newValue.replace(/\.(\d*)$/, ',$1');
              // } else {
                newValue = newValue.replace(/,(\d*)$/, '.$1');
              // }
              ngModelCtrl.$setViewValue(newValue);
              ngModelCtrl.$render();
              element.select();
            } else if (event.keyCode === 108 || event.keyCode === 110 || event.keyCode === 190) { // Accept dots
              return true;
            } else {
              return false;
            }
          }); // end on keydown

          ngModelCtrl.$parsers.unshift(function (value) {
            if (typeof value !== 'string' || value === '') {
              return null;
            }
            value = value.replace(/,(\d*)$/, '.$1');
            var out = parseFloat(value);
            if (isNaN(out)) {
              return undefined;
            }
            return out;
          }); // end $parser

          ngModelCtrl.$formatters.unshift(function (value) {
            if (typeof value !== 'string') {
              return value;
            }
            if (isNaN(parseFloat(value))) {
              return '';
            }
            // if ($locale.NUMBER_FORMATS.DECIMAL_SEP === ',') {
            //   return value.replace(/\.(\d*)$/, ',$1');
            // }
            return value.replace(/,(\d*)$/, '.$1');
          }); // end $formatter

          ngModelCtrl.$validators.number = function (modelValue, viewValue) {
            if (modelValue === undefined || modelValue === null || modelValue === '') {
              return true;
            }
            if (isNaN(modelValue)) {
              return false;
            }
            return true;
          }; // end $validator number

          ngModelCtrl.$validators.range = function (modelValue, viewValue) {
            if ((maxAttr && modelValue > maxAttr) || (minAttr && modelValue < minAttr)) {
              return false;
            }
            return true;
          }; // end $validator range

        };  // end link function
      } // end compile function
    };
  }
  ])
  .name;
