export default class FormacaoFormandosController {
  constructor($state, UIService, NgTableParams, FormacaoFormando) {
    this.$state = $state;
    this.UI = UIService;
    this.FormacaoFormando = FormacaoFormando;
    this.NgTableParams = NgTableParams;
    this.tipos = [
      {
        id: 1,
        title: "Identificação Civil"
      }, {
        id: 2,
        title: "Identificação Militar"
      }, {
        id: 3,
        title: "Autorização de Residência"
      }, {
        id: 4,
        title: "Passaporte"
      }
    ];

    this.hasLoaded = false;

    this.loadData();
  };

  hasSelect = () => {
    return _.some(this.formandos, function (r) {
      return r.selected === true;
    });
  };

  selectItem = () => {
    this.selected = _.filter(this.formandos, function (r) {
      return r.selected === true;
    }).length;
  };


  loadData = () => {
    this.hasLoaded = false;
    this.FormacaoFormando.find({
      filter: {
        where: {
          active: 1
        },
        order: 'nome desc'
      }
    }).$promise.then((res) => {
      res = _.filter(res, (r) => {
        r.selected = false;
        return r;
      });
      this.formandos = res;
      let NgTableParams = this.NgTableParams;
      this.tableData = new NgTableParams({}, {
        dataset: res,
      });
      this.hasLoaded = true;
    }).catch((err) => {
      this.hasLoaded = true;
      this.UI.addToast("Não foi possível obter formandos. Por favor tente mais tarde.");
    });
  };

  documentType = r => {
    return this.tipos.find(f => f.id === r.documentoTipo);
  };
}

FormacaoFormandosController.$inject = ['$state', 'UIService', 'NgTableParams', 'FormacaoFormando'];
