import {routes} from './entidadesproprietarias.routes';
import AtvEntidadesProprietariasListController from "./directory/directory.controller";
import AtvEntidadesProprietariasDetailsController from "./details/details.controller";
import AtvPropriedadeDetailsController from "./propriedade/details.controller";

export default angular.module('app.ativos.entidadesproprietarias', [])
  .config(routes)
  .controller('AtvEntidadesProprietariasListController', AtvEntidadesProprietariasListController)
  .controller('AtvEntidadesProprietariasDetailsController', AtvEntidadesProprietariasDetailsController)
  .controller('AtvPropriedadeDetailsController', AtvPropriedadeDetailsController)
  .name;
