import React, { useState } from 'react';
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
const DialogRelatorioProcesso = ({ open, onClose, coreListasRelatorio }) => {
    const [selectedRelatorio, setSelectedRelatorio] = useState(null);
    return (<form>
      <Dialog open={open} maxWidth={'md'} fullWidth>
        <DialogTitle> Escolha de Lista para Relatório</DialogTitle>
        <DialogContent>
            <Autocomplete disablePortal id='combo-box-demo' options={coreListasRelatorio} getOptionLabel={option => option.titulo} onChange={(event, value) => setSelectedRelatorio(value)} sx={{ mt: 1 }} renderInput={params => <TextField {...params} label='Selecione uma lista para o relatório'/>}/>
        </DialogContent>

        <DialogActions>
          <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2}>
            <Button type='button' onClick={() => onClose(undefined)}>
              Cancelar
            </Button>
              <Button onClick={() => onClose(selectedRelatorio)} disabled={!selectedRelatorio} variant='contained'>
                Gerar novo relatório
              </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </form>);
};
export default DialogRelatorioProcesso;
