import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { ListItem } from '@mui/material';
function DraggableListItem({ itemKey, index, item, children, action, isDragDisabled }) {
    return (<Draggable draggableId={`${item[itemKey]}`} index={index} isDragDisabled={isDragDisabled}>
      {(provided, snapshot) => (<ListItem secondaryAction={snapshot.isDragging ? undefined : action} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} selected={snapshot.isDragging}>
          {children.map((component, index) => (<React.Fragment key={index}>{component}</React.Fragment>))}
        </ListItem>)}
    </Draggable>);
}
export default DraggableListItem;
