export default class OblClienteDetailsController {
  constructor($state, OblCliente, UIService) {
    this.$state = $state;
    this.OblCliente = OblCliente;
    this.UI = UIService;
    this.id = $state.params.id;
    if (!this.id) {
      $state.go('app.obler.clientes.list');
    }
    this.loadData();
  }

  loadData = () => {
    this.loaded = false;
    this.OblCliente.findById({ id: this.id })
      .$promise.then(r => {
        this.data = r;
        this.loaded = true;
      })
      .catch(e => {
        this.UI.addToast('Ocorreu um erro ao carregar cliente');
        this.$state.go('app.obler.clientes.list');
      });
  };

  setParceiro = () => {
    this.UI.showConfirm(`Deseja ${this.data.parceiro ? 'remover' : 'marcar'} cliente como "Parceiro"?`).then(r => {
      if (r) {
        this.OblCliente.prototype$updateAttributes(
          { id: this.id },
          {
            parceiro: !this.data.parceiro
          }
        )
          .$promise.then(_ => {
            this.UI.addToast('Alterado com sucesso');
            this.data.parceiro = r;
          })
          .catch(e => {
            this.UI.addToast('Não foi possível alterar propriedade');
          });
      }
    });
  };

  edit = () => {
    this.UI.showDialog({
      template: require('./dialog.html'),
      controller: [
        '$scope',
        $scope => {
          $scope.data = angular.copy(this.data);
          $scope.data.iva = Math.floor(($scope.data.iva - 1) * 100);
          $scope.ok = () => {
            $scope.data.iva = (100 + $scope.data.iva) / 100;
            $scope.$close($scope.data);
          };
          $scope.cancel = () => {
            $scope.$dismiss();
          };
        }
      ]
    }).then(r => {
      if (r) {
        this.OblCliente.upsert(r)
          .$promise.then(_ => {
            this.loadData();
            this.UI.addToast('Alterado com sucesso');
          })
          .catch(e => {
            this.UI.addToast('Não foi possível alterar propriedades');
            this.loadData();
          });
      }
    });
  };
}

OblClienteDetailsController.$inject = ['$state', 'OblCliente', 'UIService'];
