import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, IconButton, ListItem, ListItemText, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { MdiIcon } from '@components';
import { mdiDelete, mdiPlus } from '@mdi/js';
import { v4 } from 'uuid';
export var ValidacaoModulo;
(function (ValidacaoModulo) {
    ValidacaoModulo["PARAMETRO"] = "PARAMETRO";
    ValidacaoModulo["ENSAIO"] = "ENSAIO";
    ValidacaoModulo["EQUIPAMENTO"] = "EQUIPAMENTO";
})(ValidacaoModulo || (ValidacaoModulo = {}));
const Comparadores = [
    { id: '=', label: 'Igual' },
    { id: '!=', label: 'Diferente' },
    { id: '>', label: 'Maior que' },
    { id: '<', label: 'Menor que' },
    { id: '>=', label: 'Maior ou igual que' },
    { id: '<=', label: 'Menor ou igual que' }
];
var ENSAIO;
(function (ENSAIO) {
    ENSAIO["DINAMICO"] = "DINAMICO";
    ENSAIO["ESTATICO"] = "ESTATICO";
})(ENSAIO || (ENSAIO = {}));
const TipoEnsaios = [
    { id: ENSAIO.DINAMICO, label: 'Dinâmico' },
    { id: ENSAIO.ESTATICO, label: 'Estático' }
];
const ModuloRegra = [
    { id: ValidacaoModulo.PARAMETRO, label: 'Parâmetros' },
    { id: ValidacaoModulo.ENSAIO, label: 'Ensaios' },
    { id: ValidacaoModulo.EQUIPAMENTO, label: 'Equipamentos' }
];
let params = [];
function ValidacaoDialog({ initial, resultados, defeitos, ensaios, onClose, ...props }) {
    const [list, setList] = useState([]);
    const paramSchema = yup.object({
        tipo: yup.number().required('Escolha uma opção da lista'),
        valores: yup.array(yup.number()),
        comparador: yup
            .string()
            .oneOf(Comparadores.map(r => r.id), 'Opção inválida!')
            .required('Escolha uma opção da lista'),
        valor: yup.number().required('O campo é obrigatório')
    });
    const formParametro = useFormik({
        initialValues: {
            valores: []
        },
        validationSchema: paramSchema,
        validateOnMount: false,
        onSubmit: d => { }
    });
    const ensaioSchema = yup.array().of(yup.object({
        comparador: yup
            .string()
            .oneOf(Comparadores.map(r => r.id), 'Opção inválida!')
            .required('Escolha uma opção da lista'),
        tipo: yup
            .string()
            .oneOf(TipoEnsaios.map(r => r.id), 'Opção inválida!')
            .required('Escolha uma opção da lista'),
        ponto: yup.number().required('O campo é obrigatório'),
        valor: yup.number().required('O campo é obrigatório')
    }));
    const formEnsaios = useFormik({
        initialValues: {
            regras: []
        },
        validationSchema: ensaioSchema,
        validateOnMount: false,
        onSubmit: e => { }
    });
    const validationSchema = yup
        .object({
        designacao: yup.string().required('O campo é obrigatório'),
        observacoes: yup.string().nullable(),
        on: yup.string().required('O campo é obrigatório'),
        coreListaRelatorioResultadoId: yup
            .number()
            .oneOf(resultados.map(r => r.id), 'Opção inválida!')
            .required('Escolha uma opção da lista')
    })
        .test({
        name: 'sub-form',
        message: 'Preencha corretamente o formulário baseado na validação a aplicar',
        test: value => {
            if (value.on == undefined) {
                return false;
            }
            if (value.on == ValidacaoModulo.PARAMETRO)
                return formParametro.isValid;
            if (value.on == ValidacaoModulo.ENSAIO)
                return formEnsaios.isValid;
        }
    });
    const formik = useFormik({
        initialValues: {},
        validationSchema: validationSchema,
        validateOnMount: false,
        onSubmit: async (values) => {
            if (values.on == ValidacaoModulo.PARAMETRO)
                values.validador = JSON.stringify(formParametro.values);
            if (values.on == ValidacaoModulo.ENSAIO)
                values.validador = JSON.stringify(formEnsaios.values.regras);
            onClose(values);
        }
    });
    useEffect(() => {
        formik.resetForm();
        formik.setValues(initial);
        if (initial.on != undefined) {
            if (initial.validador != undefined) {
                if (initial.on == ValidacaoModulo.PARAMETRO) {
                    let x = JSON.parse(initial.validador);
                    if (x.length == 0) {
                    }
                    let l = JSON.parse(initial.validador);
                    const d = defeitos.find(r => r.id == l.tipo);
                    setList(d.Defeitos);
                    params = [...d.Defeitos.filter(r => l.valores.includes(r.id))];
                    formParametro.setValues(l);
                }
                if (initial.on == ValidacaoModulo.ENSAIO) {
                    let l = JSON.parse(initial.validador);
                    l.forEach(r => {
                        r.i = v4();
                    });
                    formEnsaios.setFieldValue('regras', l);
                }
            }
        }
    }, [initial]);
    return (<Dialog {...props} fullWidth maxWidth={'lg'}>
      <DialogTitle>Regra de validação</DialogTitle>
      <DialogContent>
        <Box>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={12} xs={12}>
              <TextField label='Designação' name='designacao' fullWidth defaultValue={formik.values?.designacao} onChange={formik.handleChange} onBlur={formik.handleBlur} error={formik.touched.designacao && Boolean(formik.errors.designacao)} helperText={formik.touched.designacao && formik.errors.designacao}/>
            </Grid>
            <Grid lg={12} xs={12}>
              <TextField label='Observações' name='observacoes' fullWidth defaultValue={formik.values?.observacoes} onChange={formik.handleChange} onBlur={formik.handleBlur} error={formik.touched.observacoes && Boolean(formik.errors.observacoes)} helperText={formik.touched.observacoes && formik.errors.observacoes}/>
            </Grid>
            <Grid lg={6} xs={12}>
              <TextField label='Resultado' name='coreListaRelatorioResultadoId' fullWidth defaultValue={formik.values?.coreListaRelatorioResultadoId} onChange={formik.handleChange} onBlur={formik.handleBlur} error={formik.touched.coreListaRelatorioResultadoId && Boolean(formik.errors.coreListaRelatorioResultadoId)} helperText={formik.touched.coreListaRelatorioResultadoId && formik.errors.coreListaRelatorioResultadoId} select>
                <MenuItem value={undefined} disabled></MenuItem>
                {resultados &&
            resultados.map(x => (<MenuItem value={x.id} key={x.id}>
                      {x.designacao}
                    </MenuItem>))}
              </TextField>
            </Grid>
            <Grid lg={6} xs={12}>
              <TextField label='Aplicar a' name='on' fullWidth defaultValue={formik.values?.on} onChange={formik.handleChange} onBlur={formik.handleBlur} error={formik.touched.on && Boolean(formik.errors.on)} helperText={formik.touched.on && formik.errors.on} select>
                <MenuItem value={undefined} disabled></MenuItem>
                {ModuloRegra.map(x => (<MenuItem value={x.id} key={x.id}>
                    {x.label}
                  </MenuItem>))}
              </TextField>
            </Grid>
          </Grid>
          {formik.values.on == ValidacaoModulo.PARAMETRO && (<Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid lg={6} xs={12}>
                <TextField label='Tipo de defeito' name='tipo' fullWidth value={formParametro.values?.tipo} onChange={_ => {
                const d = defeitos.find(r => r.id.toLocaleString() == _.target.value);
                setList(d.Defeitos);
                formParametro.handleChange(_);
            }} onBlur={formParametro.handleBlur} error={formParametro.touched.tipo && Boolean(formParametro.errors.tipo)} helperText={formParametro.touched.tipo && formParametro.errors.tipo} select>
                  <MenuItem value={undefined} disabled></MenuItem>
                  {defeitos.map(x => (<MenuItem value={x.id} key={x.id}>
                      {x.designacao}
                    </MenuItem>))}
                </TextField>
              </Grid>
              <Grid lg={3} xs={6}>
                <TextField label='Comparador' name='comparador' fullWidth value={formParametro.values.comparador} onChange={formParametro.handleChange} onBlur={formParametro.handleBlur} error={formParametro.touched.comparador && Boolean(formParametro.errors.comparador)} helperText={formParametro.touched.comparador && formParametro.errors.comparador} select>
                  <MenuItem value={undefined} disabled></MenuItem>
                  {Comparadores.map(x => (<MenuItem value={x.id} key={x.id}>
                      {x.label} ({x.id})
                    </MenuItem>))}
                </TextField>
              </Grid>
              <Grid lg={3} xs={6}>
                <TextField label='Quantidade' name='valor' fullWidth value={formParametro.values.valor} onChange={formParametro.handleChange} onBlur={formParametro.handleBlur} error={formParametro.touched.valor && Boolean(formParametro.errors.valor)} helperText={formParametro.touched.valor && formParametro.errors.valor}/>
              </Grid>
              <Grid lg={12} xs={12}>
                <Autocomplete id='valores' multiple limitTags={3} defaultValue={params} disabled={list.length == 0} options={list} getOptionLabel={list => `${list.titulo} ${list.subtitulo}`} getOptionDisabled={option => formParametro.values.valores.includes(option.id)} onChange={(_, value) => {
                formParametro.setFieldValue('valores', value.map(r => r.id));
            }} onOpen={formParametro.handleBlur} renderOption={(props, option) => (<ListItem {...props} key={option.id}>
                      <ListItemText primary={option.titulo} secondary={option.subtitulo}></ListItemText>
                    </ListItem>)} renderTags={(list, props) => list.map((option, index) => {
                return <Chip {...props({ index })} key={`${index}`} label={option.titulo}/>;
            })} renderInput={params => (<TextField {...params} fullWidth onBlur={formParametro.handleBlur} error={formParametro.touched.valores && Boolean(formParametro.errors.valores)} helperText={formParametro.touched.valores && formParametro.errors.valores} label='Selecionar defeitos' name='valores'/>)}/>
                <FormHelperText>A ausência de seleção de defeitos aplica a regra a todos os defeitos do tipo selecionado</FormHelperText>
              </Grid>
            </Grid>)}
          {formik.values.on == ValidacaoModulo.ENSAIO && (<Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>Ponto a validar</TableCell>
                  <TableCell>Comparador</TableCell>
                  <TableCell>Propriedade</TableCell>
                  <TableCell>Valor</TableCell>
                  <TableCell>
                    <IconButton onClick={() => {
                formEnsaios.setFieldValue('regras', [
                    ...formEnsaios.values.regras,
                    {
                        i: v4(),
                        comparador: undefined,
                        tipo: undefined,
                        valor: undefined,
                        ponto: undefined
                    }
                ]);
            }}>
                      <MdiIcon path={mdiPlus}/>
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formEnsaios.values.regras.map((r, index) => (<TableRow key={r.i}>
                    <TableCell>
                      <Autocomplete id={`regras.${index}.ponto`} options={ensaios} getOptionLabel={list => `${list.codigo} -  ${list.designacao}`} onChange={(event, value) => {
                    formEnsaios.setFieldValue(`regras.${index}.ponto`, value.id);
                }} defaultValue={ensaios.find(e => e.id == r.ponto)} 
            //includeInputInList
            renderOption={(props, option) => (<ListItem key={option.id} {...props}>
                            <ListItemText primary={option.codigo} secondary={option.designacao}></ListItemText>
                          </ListItem>)} renderInput={params => (<TextField {...params} fullWidth onBlur={formEnsaios.handleBlur} error={formEnsaios.touched?.[index]?.ponto && Boolean(formEnsaios.errors?.[index]?.ponto)} helperText={formEnsaios.touched?.[index]?.ponto && formEnsaios.errors?.[index]?.ponto} label='Selecionar ponto' name={`regras.${index}.ponto`}/>)}/>
                    </TableCell>
                    <TableCell>
                      <TextField name={`regras.${index}.comparador`} fullWidth value={r.comparador} onChange={formEnsaios.handleChange} onBlur={formEnsaios.handleBlur} error={formEnsaios.touched?.[index]?.comparador && Boolean(formEnsaios.errors?.[index]?.comparador)} helperText={formEnsaios.touched?.[index]?.comparador && formEnsaios.errors?.[index]?.comparador} select>
                        <MenuItem value={undefined} disabled></MenuItem>
                        {Comparadores.map(x => (<MenuItem value={x.id} key={x.id}>
                            {x.label} ({x.id})
                          </MenuItem>))}
                      </TextField>
                    </TableCell>
                    <TableCell>
                      <TextField name={`regras.${index}.tipo`} fullWidth value={r.tipo} onChange={formEnsaios.handleChange} onBlur={formEnsaios.handleBlur} error={formEnsaios.touched?.[index]?.tipo && Boolean(formEnsaios.errors?.[index]?.tipo)} helperText={formEnsaios.touched?.[index]?.tipo && formEnsaios.errors?.[index]?.tipo} select>
                        <MenuItem value={undefined} disabled></MenuItem>
                        {TipoEnsaios.map(x => (<MenuItem value={x.id} key={x.id}>
                            {x.label}
                          </MenuItem>))}
                      </TextField>
                    </TableCell>
                    <TableCell>
                      {(r.tipo == undefined || r.tipo == ENSAIO.ESTATICO) && (<TextField name={`regras.${index}.valor`} fullWidth disabled={r.tipo == undefined} value={r.valor} onChange={formEnsaios.handleChange} onBlur={formEnsaios.handleBlur} error={formEnsaios.touched?.[index]?.valor && Boolean(formEnsaios.errors?.[index]?.valor)} helperText={formEnsaios.touched?.[index]?.valor && formEnsaios.errors?.[index]?.valor}/>)}
                      {r.tipo == ENSAIO.DINAMICO && (<Autocomplete id={`regras.${index}.valor`} options={ensaios} defaultValue={ensaios.find(e => e.id == r.valor)} getOptionLabel={list => `${list.codigo} -  ${list.designacao}`} onChange={(event, value) => {
                        formEnsaios.setFieldValue(`regras.${index}.valor`, value.id);
                    }} 
                //includeInputInList
                renderOption={(props, option) => (<ListItem key={option.id} {...props}>
                              <ListItemText primary={option.codigo} secondary={option.designacao}></ListItemText>
                            </ListItem>)} getOptionDisabled={option => option.id == r.ponto} renderInput={params => (<TextField {...params} fullWidth onBlur={formEnsaios.handleBlur} error={formEnsaios.touched?.[index]?.ponto && Boolean(formEnsaios.errors?.[index]?.ponto)} helperText={formEnsaios.touched?.[index]?.ponto && formEnsaios.errors?.[index]?.ponto} label='Selecionar ponto' name={`regras.${index}.valor`}/>)}/>)}
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => {
                    const l = [...formEnsaios.values.regras];
                    l.splice(index, 1);
                    formEnsaios.setFieldValue('regras', l);
                }}>
                        <MdiIcon path={mdiDelete}/>
                      </IconButton>
                    </TableCell>
                  </TableRow>))}
              </TableBody>
            </Table>)}
          {formik.values.on == ValidacaoModulo.EQUIPAMENTO && <Grid container spacing={2} sx={{ mt: 1 }}></Grid>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button type='button' onClick={() => onClose(undefined)}>
          Cancelar
        </Button>
        <Button onClick={() => {
            formik.handleSubmit();
        }} disabled={!formik.isValid || !formik.dirty || formik.isSubmitting} variant='contained'>
          OK
        </Button>
      </DialogActions>
    </Dialog>);
}
export default ValidacaoDialog;
