import React, { forwardRef } from 'react';
import Icon from '@mdi/react';
import { Box } from '@mui/system';
export const MdiIcon = forwardRef((prop, ref) => {
    const defaultProps = {
        display: 'flex',
        alignItems: 'center'
    };
    const sxProps = { ...defaultProps, ...prop.sx };
    return (<Box sx={sxProps}>
      <Icon path={prop.path} size={prop.size ?? 1}/>
    </Box>);
});
