export function routes($stateProvider) {
  $stateProvider
    .state('app.elv.oi', {
      url: '/ordens-intervencao',
      abstract: true
    })
    .state('app.elv.oi.list', {
      url: '/',
      title: 'Ordens de Intervenção',
      template: require('./directory/directory.view.html'),
      controller: 'DirectoryIntervencoesController',
      controllerAs: 'vm',
      role: 'elvListOI'
    })
    .state('app.elv.oi.details', {
      url: '/{id}',
      title: 'Detalhes da Ordem de Intervenção',
      template: require('./details/details.view.html'),
      controller: 'DetalhesIntervencoesController',
      controllerAs: 'vm',
      role: 'elvListOI'
    })
    .state('app.elv.oi.new', {
      url: '/nova',
      title: 'Adicionar Ordem de Intervenção',
      template: require('./new/new.view.html'),
      controller: 'CreateIntervencaoController',
      controllerAs: 'vm',
      role: 'elvImportOI'
    })
}

routes.$inject = ['$stateProvider'];
