import _ from 'lodash';
import moment from 'moment';

export default class CalendarizacaoElevadoresController {
  constructor($rootScope, $state, $sce, $filter, AuthenticationService, NgTableParams, ElvProcesso, Funcionario, ElvAgendamento, Avatar) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$sce = $sce;
    this.$filter = $filter;
    this.user = AuthenticationService.getUser();
    this.ElvProcesso = ElvProcesso;
    this.Funcionario = Funcionario;
    this.ElvAgendamento = ElvAgendamento;
    this.NgTableParams = NgTableParams;

    this.Avatar = Avatar;
    this.search = "";
    this.clients = [];
    this.selected = 0;
    this.select = false;

    this.calendarLoading = true;

    this.calendarOptions = {
      eventClick: this.showAgendamentoDetail,
      defaultView: 'agendaWeek',
      locale: 'pt',
      themeSystem: 'bootstrap4',
      header: {
        left: 'prev,next today',
        center: 'title',
        right: 'month,agendaWeek,agendaDay,listMonth'
      },
      navLinks: true,
      defaultTimedEventDuration: '01:00:00',
      allDaySlot: false,
      displayEventEnd: false,
      timeFormat: "HH:mm",
      minTime: "08:00:00",
      maxTime: "22:00:00",
      height: 'auto',
      slotDuration: "00:30:00",
      slotLabelFormat: "HH:mm",
      views: {
        agendaWeek: {
          columnHeaderFormat: "DD/MM"
        }
      },
      firstDay: 1,
      nowIndicator: true,
      weekends: true,
      hiddenDays: [0],
      weekNumberTitle: 'Sem.',
      eventLimitText: 'agd.',
      noEventsMessage: 'Sem agendamentos a apresentar.',
      weekNumbers: true,
      eventLimit: true,
      buttonText: {
        today: 'Hoje',
        month: 'Mensal',
        week: 'Semanal',
        day: 'Diário',
        list: 'Lista'
      }
    };
    this.agendamentos = [];
    this.calendarEvents = [];

    this.getAgendamentos();
  };

  getAgendamentos = () => {
    this.ElvAgendamento.find({
      filter: {
        where: {
          active: 1,
        },
        include: ['Funcionario', {
          relation: 'Pedido',
          scope: {
            where: {
              estadoId: 2 // Only show "Agendados" agendamentos
            },
            include: ['estado', 'elevadorPed']
          }
        }]
      }
    }).$promise.then((res) => {
      // Redundant because of query, but ignore agendamentos "In Agendamento" (We need to not consider Agendamentos without Pedidoinspeccao with estado 4).
      this.agendamentos = _.filter(res, (r) => (r.hora != null) && (r.Pedido != null) && (r.Pedido.estado.designacao === "Agendado") && (r.Pedido.estado.active === 1));
      this.calendarEvents = [];
      for (let i = 0; i < this.agendamentos.length; i++) {
        let agDate = moment(this.agendamentos[i].data).startOf('day');
        let aux = moment(this.agendamentos[i].hora, 'HH:mm:ss');
        agDate.set({hour: aux.get('hour'), minute: aux.get('minute'), second: aux.get('second')});

        let composedAddress = this.agendamentos[i].Funcionario.name + "\n"
        //   this.agendamentos[i].Pedido.elevadorPed.cp4 + "-" +
        //   this.agendamentos[i].Pedido.elevadorPed.cp3 + " " +
        //   this.agendamentos[i].Pedido.elevadorPed.localidade;

        this.calendarEvents.push({
          id: this.agendamentos[i].id,
          title: composedAddress,
          start: agDate,
          color: this.Avatar.getColor(this.Avatar.getInitials(this.agendamentos[i].Funcionario.name))
        });
      }
      this.calendarLoading = false;
    });
  };

  showAgendamentoDetail = (calEvent, jsEvent, view) => {
    let ag = _.find(this.agendamentos, {'id': calEvent.id});
    this.$state.go('app.elv.processos.details', {id: ag.processoId});
  };

}

CalendarizacaoElevadoresController.$inject = ['$rootScope', '$state', '$sce', '$filter', 'AuthenticationService', 'NgTableParams', 'ElvProcesso', 'Funcionario', 'ElvAgendamento', 'Avatar'];
