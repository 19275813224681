export function routes($stateProvider) {
  $stateProvider
    .state('app.elv.processos', {
      url: '/processos',
      abstract: true,
      template: '<div ui-view></div>'
    })
    .state('app.elv.processos.list', {
      url: '?{page:int}&{items:int}&{state:int}&order&sort&filter',
      title: 'Gestão de Processos',
      template: require('./directory/directory.view.html'),
      controller: 'DirectoryPedidosElevadoresController',
      controllerAs: 'vm',
      role: 'elvListarProcessos',
      params: {
        state: {
          value: 1,
          dynamic: true
        },
        page: {
          value: 1,
          dynamic: true
        },
        items: {
          value: 20,
          dynamic: true
        },
        order: {
          value: "id",
          dynamic: true
        },
        sort: {
          value: "desc",
          dynamic: true
        },
        filter: {
          dynamic: true
        }
      }
    })
    .state('app.elv.processos.details', {
      url: '/{id}',
      title: 'Visualizar Processo',
      template: require('./details/details.view.html'),
      controller: 'DetailPedidosElevadoresController',
      controllerAs: 'vm',
      role: 'elvListarProcessos'
    })
    .state('app.elv.processos.new', {
      url: '/novo',
      title: 'Adicionar Processo',
      template: require('./new/new.view.html'),
      controller: 'CreatePedidoController',
      controllerAs: 'vm',
      role: 'elvImportOI'
    })
    .state('app.elv.processos.new.1', {
      template: require('./new/details.form.html'),
      role: 'elvImportOI'
    })
    .state('app.elv.processos.new.2', {
      template: require('./new/elevador.form.html'),
      role: 'elvImportOI'
    })
    .state('app.elv.processos.new.3', {
      template: require('./new/emie.form.html'),
      role: 'elvImportOI'
    })
    .state('app.elv.processos.new.4', {
      template: require('./new/condominio.form.html'),
      role: 'elvImportOI'
    })
}

routes.$inject = ['$stateProvider'];
