import _ from "lodash";

export default class DashboardIEService {
  constructor(AuthenticationService, Pedidoinspeccao, Requisitante, Pagamento, Agendamento, Estado, $q, $filter) {
    this.Authentication = AuthenticationService;
    this.$q = $q;
    this.$filter = $filter;
    this.Pedidoinspeccao = Pedidoinspeccao;
    this.Requisitante = Requisitante;
    this.Pagamento = Pagamento;
    this.Agendamento = Agendamento;
    this.Estado = Estado;
  }

  getPedidos = () => {
    let defer = this.$q.defer();
    this.Pedidoinspeccao.dashboard().$promise.then(req => defer.resolve(req)).catch(err => defer.reject(err));
    return defer.promise;
  };

  getRequisitantes = () => {
    let defer = this.$q.defer();
    this.Requisitante.dashboard().$promise.then(req => defer.resolve(req)).catch(err => defer.reject(err));
    return defer.promise;
  };

  getPagamentos = () => {
    let defer = this.$q.defer();
    this.Pagamento.dashboard().$promise.then(req => defer.resolve(req)).catch(err => defer.reject(err));
    return defer.promise;
  };

  getEstados = () => {
    let defer = this.$q.defer();
    this.Estado.dashboard().$promise.then(req => defer.resolve(req)).catch(err => defer.reject(err));
    return defer.promise;
  };

  /*
   * Params:
   * data    - Data to generate chart
   * labels  - Data to generate chart
   * period  - Type of chart to display ('weekly','monthly','yearly','state1','state2')
   * type    - Datatype (Pedidos, Clientes, Pagamentos, Pago...)
   * */
  createChartFor = (data, labels, period, type) => {

    let colors = {
      Pedidos: '#1976d2',
      Clientes: '#7b1fa2',
      Pagamentos: '#c2185b',
      Pago: '#388e3c',
      Estado: '',
    };

    let filter = this.$filter;

    // Get max for scale purposes
    let subdivisions = 5;
    let max = _.max(data);
    let maxScale = max + (subdivisions - max % subdivisions);
    let step = maxScale / subdivisions;

    //Create chart object
    return {
      colors: [colors[type]],
      series: [type === 'Pago' ? 'Valor ' + type : type],
      labels: labels,
      data: (type === 'Estado') ? data : [data],
      options: (type !== 'Estado') ? {
        layout: {
          padding: {
            top: 24,
            right: 0,
            left: 0,
            bottom: 0
          }
        },
        tooltips: {
          enabled: true
        },
        hover: {
          animationDuration: 1
        },
        animation: {
          duration: 1,
          onComplete: function () {
            let chartInstance = this.chart,
              ctx = chartInstance.ctx;
            ctx.textAlign = 'center';
            ctx.fillStyle = "rgba(0, 0, 0, 1)";
            ctx.textBaseline = 'bottom';
            if (period !== 'monthly') {
              this.data.datasets.forEach(function (dataset, i) {
                let meta = chartInstance.controller.getDatasetMeta(i);
                meta.data.forEach(function (bar, index) {
                  var data = dataset.data[index];
                  if (type === 'Pago') {
                    data = filter('currency')(data, '€', 2)
                  }
                  ctx.fillText(data, bar._model.x, bar._model.y - 5);

                });
              });
            }
          }
        },
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              stepSize: step,
              suggestedMax: maxScale,
            },
            gridLines: {
              drawBorder: true,
              display: true
            }
          }],
          xAxes: [{
            gridLines: {
              drawBorder: true,
              display: false
            }
          }]
        }
      } : {
        maintainAspectRatio: false,
        legend: {
          display: true
        },
        tooltips: {
          enabled: true
        }
      },
    };
  };

  getScheduleConfig = () => {
    return {
      defaultView: 'agendaWeek',
      locale: 'pt',
      themeSystem: 'bootstrap4',
      header: {
        left: 'prev,next today',
        center: 'title',
        right: 'month,agendaWeek,agendaDay,listMonth'
      },
      navLinks: true,
      defaultTimedEventDuration: '01:00:00',
      allDaySlot: false,
      displayEventEnd: false,
      timeFormat: "HH:mm",
      minTime: "08:00:00",
      maxTime: "22:00:00",
      height: 'auto',
      slotDuration: "00:30:00",
      slotLabelFormat: "HH:mm",
      views: {
        agendaWeek: {
          columnHeaderFormat: "DD/MM"
        }
      },
      firstDay: 1,
      nowIndicator: true,
      weekends: false,
      weekNumberTitle: 'Sem.',
      eventLimitText: 'agd.',
      noEventsMessage: 'Sem agendamentos a apresentar.',
      weekNumbers: true,
      eventLimit: true,
      buttonText: {
        today: 'Hoje',
        month: 'Mensal',
        week: 'Semanal',
        day: 'Diário',
        list: 'Lista'
      }
    };
  };

  getSchedules = () => {
    let defer = this.$q.defer();

    this.Agendamento.find({
      filter: {
        where: {
          active: 1,
          tecnicoId: this.Authentication.getId() || -1,
          data: {
            neq: null
          },
          hora: {
            neq: null
          }
        },
        include: {
          relation: 'Pedidoinspeccao',
          scope: {
            include: 'Dadosinstalacao'
          }
        }
      }
    }).$promise.then((res) => {
      // Handle our response to our "Event" format before resolving
      let agendamentos = [];
      res.forEach(r => {
        // Data format
        let agDate = moment(r.data).startOf('day');
        let aux = moment(r.hora, 'HH:mm:ss');
        agDate.set({hour: aux.get('hour'), minute: aux.get('minute'), second: aux.get('second')});
        // Address with CP
        let composedAddress = r.Pedidoinspeccao.Dadosinstalacao.morada + "\n" +
          r.Pedidoinspeccao.Dadosinstalacao.codigoPostal1 + "-" +
          r.Pedidoinspeccao.Dadosinstalacao.codigoPostal2 + " " +
          r.Pedidoinspeccao.Dadosinstalacao.codigoPostal3;

        let color = (agDate.isBefore(moment())) ? '#607d8b':'#1976d2';

        agendamentos.push({
          id: r.id,
          pedidoId: r.pedidoId,
          title: composedAddress,
          start: agDate,
          color: color
        });
      });
      defer.resolve(agendamentos);
    }).catch(err => defer.reject(err));

    return defer.promise;
  };
}

DashboardIEService.$inject = ['AuthenticationService', 'Pedidoinspeccao', 'Requisitante', 'Pagamento', 'Agendamento', 'Estado', '$q', '$filter'];
