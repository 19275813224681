export function routes($stateProvider) {
  $stateProvider
    .state('app.elv.relatorios', {
      url: '/relatorio',
      abstract: true
    })
    .state('app.elv.relatorios.list', {
      url: '?{page:int}&{items:int}&{state:int}&order&sort&filter',
      title: 'Relatórios',
      template: require('./directory/directory.view.html'),
      controller: 'DirectoryRelatorioController',
      controllerAs: 'vm',
      role: 'pedidoIeList',
      params: {
        state: {
          value: 1,
          dynamic: true
        },
        page: {
          value: 1,
          dynamic: true
        },
        items: {
          value: 20,
          dynamic: true
        },
        order: {
          value: "id",
          dynamic: true
        },
        sort: {
          value: "desc",
          dynamic: true
        },
        filter: {
          dynamic: true
        }
      }
    })
    .state('app.elv.relatorios.details', {
      url: '/{rep:int}',
      title: 'Detalhes do Relatório',
      template: require('./details/details.view.html'),
      controller: 'DetalhesRelatorioController',
      controllerAs: 'vm',
      role: 'pedidoIeList'
    });
}

routes.$inject = ['$stateProvider'];
