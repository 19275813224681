import angular from 'angular';

import { routes } from "./financeiro.routes";
import FinanceiroDirectoryController from "./directory/directory.controller";

import FinanceiroService from "./financeiro.service";

export default angular.module('app.financeiro', [])
  .config(routes)
  .controller('FinanceiroDirectoryController', FinanceiroDirectoryController)
  .service('FinanceiroService', FinanceiroService)
  .name;
