import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid';
import * as yup from 'yup';
import { useFormik } from 'formik';
const DialogClienteProcesso = ({ open, initial, onClose }) => {
    const validationSchema = yup.object({
        nomeCliente: yup.string().required('Nome é obrigatório'),
        nifCliente: yup.string().required('NIF é obrigatório'),
        emailCliente: yup.string().email('Email inválido').notRequired(),
        moradaCliente: yup.string().required('Morada é obrigatória'),
        cep: yup.string().matches(/^\d{4}-\d{3}$/, 'Código Postal deve estar no formato XXXX-YYY').notRequired(),
        concelhoCliente: yup.string().required('Concelho é obrigatório'),
        distritoCliente: yup.string().required('Distrito é obrigatório'),
    });
    const formik = useFormik({
        initialValues: {},
        validationSchema: validationSchema,
        validateOnMount: false,
        onSubmit: async (values) => {
            onClose(values);
        }
    });
    useEffect(() => {
        formik.resetForm();
        formik.setValues(initial);
    }, [initial]);
    return (<form>
      <Dialog open={open} maxWidth={'lg'} fullWidth>
        <DialogTitle>Editar Informação do Cliente para o Processo</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={12} md={12} xs={12}>
              <TextField label='Nome' name='nomeCliente' defaultValue={formik.values?.nomeCliente} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.nomeCliente && Boolean(formik.errors.nomeCliente)} helperText={formik.touched.nomeCliente && formik.errors.nomeCliente}/>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={4} md={4} xs={12}>
              <TextField label='NIF' name='nifCliente' defaultValue={formik.values?.nifCliente} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.nifCliente && Boolean(formik.errors.nifCliente)} helperText={formik.touched.nifCliente && formik.errors.nifCliente}/>
            </Grid>
            <Grid lg={8} md={8} xs={12}>
              <TextField label='Email' name='emailCliente' defaultValue={formik.values?.emailCliente} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.emailCliente && Boolean(formik.errors.emailCliente)} helperText={formik.touched.emailCliente && formik.errors.emailCliente}/>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={12} md={12} xs={12}>
              <TextField label='Morada' name='moradaCliente' defaultValue={formik.values?.moradaCliente} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.moradaCliente && Boolean(formik.errors.moradaCliente)} helperText={formik.touched.moradaCliente && formik.errors.moradaCliente}/>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={2} md={2} xs={12}>
              <TextField label='Código Postal' name='cep' defaultValue={formik.values?.cep} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.cep && Boolean(formik.errors.cep)} helperText={formik.touched.cep && formik.errors.cep}/>
            </Grid>
            <Grid lg={5} md={5} xs={12}>
              <TextField label='Concelho' name='concelhoCliente' defaultValue={formik.values?.concelhoCliente} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.concelhoCliente && Boolean(formik.errors.concelhoCliente)} helperText={formik.touched.concelhoCliente && formik.errors.concelhoCliente}/>
            </Grid>
            <Grid lg={5} md={5} xs={12}>
              <TextField label='Distrito' name='distritoCliente' defaultValue={formik.values?.distritoCliente} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.distritoCliente && Boolean(formik.errors.distritoCliente)} helperText={formik.touched.distritoCliente && formik.errors.distritoCliente}/>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type='button' onClick={() => onClose(undefined)}>
            Cancelar
          </Button>
          <Button onClick={() => {
            formik.handleSubmit();
        }} disabled={!formik.isValid || !formik.dirty || formik.isSubmitting} variant='contained'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </form>);
};
export default DialogClienteProcesso;
