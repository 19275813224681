export default class OblerAdminController {
  constructor($state, OblParametro, OblAssocServico, OblValencia, OblMotivo, UIService) {
    this.$state = $state;
    this.OblParametro = OblParametro;
    this.OblAssocServico = OblAssocServico;
    this.OblValencia = OblValencia;
    this.OblMotivo = OblMotivo;
    this.UI = UIService;
    this.page = 1;
    this.tab = undefined;
    this.start = 0;
    this.end = 0;
    this.loadData();
    // Test
  }

  loadData = () => {
    this.OblParametro.find({ filter: { where: { active: true } } }).$promise.then(r => {
      r.forEach(a => {
        a.jsonData = JSON.parse(a.data);
      });
      this.params = r;
      this.loaded = true;
    });
  };

  nextPage = () => {
    this.page++;
    this.load();
  };

  prevPage = () => {
    this.page--;
    if (this.page < 1) this.page = 1;
    this.load();
  };

  load = () => {
    if (this.tab == 'valencia') this.loadValencias();
    if (this.tab == 'servicos') this.loadServicos();
    if (this.tab == 'motivos') this.loadMotivos();
  };

  loadValencias = () => {
    this.pageLoaded = false;
    if (!this.tab == 'valencia') {
      this.page = 1;
    }
    this.OblValencia.count({
      where: {
        active: true
      }
    }).$promise.then(c => {
      this.OblValencia.find({
        filter: {
          where: {
            active: true
          },
          skip: (this.page - 1) * 10,
          limit: 10,
          include: 'categoria'
        }
      }).$promise.then(r => {
        this.start = !r.length ? 0 : (this.page - 1) * 10 + 1;
        this.end = !r.length ? 0 : (this.page - 1) * 10 + r.length;
        this.count = c.count;
        this.data = r;
        this.pageLoaded = true;
      });
    });
    this.tab = 'valencia';
  };

  loadMotivos = () => {
    this.pageLoaded = false;
    if (!this.tab == 'motivos') {
      this.page = 1;
    }
    this.OblMotivo.count({
      where: {
        active: true
      }
    }).$promise.then(c => {
      this.OblMotivo.find({
        filter: {
          where: {
            active: true
          },
          skip: (this.page - 1) * 10,
          limit: 10
        }
      }).$promise.then(r => {
        this.start = !r.length ? 0 : (this.page - 1) * 10 + 1;
        this.end = !r.length ? 0 : (this.page - 1) * 10 + r.length;
        this.count = c.count;
        this.data = r;
        this.pageLoaded = true;
      });
    });
    this.tab = 'motivos';
  };

  loadServicos = () => {
    this.pageLoaded = false;
    if (!this.tab == 'servicos') {
      this.page = 1;
    }
    this.OblAssocServico.count({
      where: {
        active: true
      }
    }).$promise.then(c => {
      this.OblAssocServico.find({
        filter: {
          where: {
            active: true
          },
          skip: (this.page - 1) * 10,
          limit: 10,
          include: 'tipo'
        }
      }).$promise.then(r => {
        this.start = !r.length ? 0 : (this.page - 1) * 10 + 1;
        this.end = !r.length ? 0 : (this.page - 1) * 10 + r.length;
        this.count = c.count;
        this.data = r;
        this.pageLoaded = true;
      });
    });
    this.tab = 'servicos';
  };

  editMotivo = data => {
    this.UI.showDialog({
      template: require('./motivo.dialog.html'),
      controller: [
        '$scope',
        $scope => {
          $scope.label = 'Adicionar';
          $scope.aux = {};
          $scope.data = {
            id: 0,
            admin: false,
            realizada: false,
            active: true
          };
          if (data) {
            $scope.data = angular.copy(data);
            $scope.data.admin = $scope.data.admin == 1;
            $scope.data.realizada = $scope.data.realizada == 1;
            $scope.label = 'Editar';
          }

          $scope.loaded = true;

          $scope.ok = () => {
            $scope.$close($scope.data);
          };

          $scope.delete = () => {
            $scope.data.active = false;
            $scope.$close($scope.data);
          };

          $scope.cancel = () => {
            $scope.$dismiss();
          };
        }
      ]
    }).then(r => {
      this.OblMotivo.upsert(r).$promise.then(n => {
        if (r.id == 0) {
          this.UI.addToast('Motivo adicionado com sucesso');
        } else {
          if (r.active == 0) {
            this.UI.addToast('Motivo eliminado com sucesso');
          } else {
            this.UI.addToast('Motivo editado com sucesso');
          }
        }
        this.loadMotivos();
      });
    });
  };

  editServico = data => {
    this.UI.showDialog({
      template: require('./servico.dialog.html'),
      controller: [
        '$scope',
        'OblTipoProcesso',
        ($scope, OblTipoProcesso) => {
          $scope.label = 'Adicionar';
          $scope.aux = {};
          $scope.data = {
            id: 0,
            active: true
          };
          if (data) {
            $scope.data = angular.copy(data);
            $scope.label = 'Editar';
          }

          OblTipoProcesso.find({ filter: { where: { active: true, id: { gt: 1 } } } }).$promise.then(r => {
            $scope.tipos = r;
            if ($scope.data.id > 0) {
              $scope.aux.selected = r.find(r => (r.id = $scope.data.tipoId));
            }
            $scope.loaded = true;
          });

          $scope.ok = () => {
            $scope.data.tipoId = $scope.aux.selected.id;
            $scope.$close($scope.data);
          };

          $scope.delete = () => {
            $scope.data.active = false;
            $scope.$close($scope.data);
          };

          $scope.cancel = () => {
            $scope.$dismiss();
          };
        }
      ]
    }).then(r => {
      this.OblAssocServico.upsert(r).$promise.then(n => {
        if (r.id == 0) {
          this.UI.addToast('Serviço adicionado com sucesso');
        } else {
          if (r.active == 0) {
            this.UI.addToast('Serviço eliminado com sucesso');
          } else {
            this.UI.addToast('Serviço editado com sucesso');
          }
        }
        this.loadServicos();
      });
    });
  };

  editValencia = data => {
    this.UI.showDialog({
      template: require('./valencia.dialog.html'),
      controller: [
        '$scope',
        'OblCategoriaValencia',
        ($scope, OblCategoriaValencia) => {
          $scope.label = 'Adicionar';
          $scope.aux = {};
          $scope.data = {
            id: 0,
            active: true
          };
          if (data) {
            $scope.data = angular.copy(data);
            $scope.label = 'Editar';
          }

          OblCategoriaValencia.find({ filter: { where: { active: true } } }).$promise.then(r => {
            $scope.categorias = r;
            if ($scope.data.id > 0) {
              $scope.aux.selected = r.find(r => (r.id = $scope.data.categoriaId));
            }
            $scope.loaded = true;
          });

          $scope.ok = () => {
            $scope.data.categoriaId = $scope.aux.selected.id;
            $scope.$close($scope.data);
          };

          $scope.delete = () => {
            $scope.data.active = false;
            $scope.$close($scope.data);
          };

          $scope.cancel = () => {
            $scope.$dismiss();
          };
        }
      ]
    }).then(r => {
      this.OblValencia.upsert(r).$promise.then(n => {
        if (r.id == 0) {
          this.UI.addToast('Valência adicionada com sucesso');
        } else {
          if (r.active == 0) {
            this.UI.addToast('Valência eliminada com sucesso');
          } else {
            this.UI.addToast('Valência editada com sucesso');
          }
        }
        this.loadValencias();
      });
    });
  };

  save = param => {
    let o = angular.copy(param);
    o.data = JSON.stringify(o.jsonData);
    delete o.jsonData;
    this.loaded = false;
    this.OblParametro.upsert(o)
      .$promise.then(r => {
        this.UI.addToast(`${param.titulo} atualizados com sucesso`);
        this.loadData();
      })
      .catch(e => {
        this.UI.addToast(`Ocorreu um erro ao atualizar ${param.titulo.toLowerCase()}`);
        this.loadData();
      });
  };
}

OblerAdminController.$inject = ['$state', 'OblParametro', 'OblAssocServico', 'OblValencia', 'OblMotivo', 'UIService'];
