import * as React from 'react';
import DraggableListItem from './DraggableListItem';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
export function DraggableList({ items, disabled, onDragEnd, itemKey, render, action }) {
    return (<DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable-list'>
        {provided => (<div ref={provided.innerRef} {...provided.droppableProps}>
            {(items || []).map((item, index) => (<DraggableListItem isDragDisabled={disabled} item={item} itemKey={itemKey} index={index} key={item[itemKey]} children={render(item)} action={action ? action(item, index) : undefined}/>))}
            {provided.placeholder}
          </div>)}
      </Droppable>
    </DragDropContext>);
}
