export function routes($stateProvider) {
  $stateProvider
    .state('app.elv.listasverificacao', {
      url: '/listaverificacao',
      abstract: true,
      template: '<div ui-view></div>'
    })
    .state('app.elv.listasverificacao.list', {
      url: '/',
      title: 'Gestão de Listas de Verificacao',
      template: require('./directory/directory.view.html'),
      controller: 'DirectoryListasVerificacaoController',
      controllerAs: 'vm',
      role: 'elvListarListasVerificacao'
    })
    .state('app.elv.listasverificacao.details', {
      url: '/{id}',
      title: 'Detalhes da Lista de Verificação',
      template: require('./details/details.view.html'),
      controller: 'DetalhesListasVerificacaoController',
      controllerAs: 'vm',
      role: 'elvListarListasVerificacao'
    })
    .state('app.elv.listasverificacao.topicodetalhe', {
      url: '/{id}/topicoverificacao',
      abstract: true,
      template: '<div ui-view></div>',
      role: 'elvListarTopicosVerificacao'
    })
    .state('app.elv.listasverificacao.topicodetalhe.details', {
      url: '/{td}',
      title: 'Detalhes do Tópico de Verificação',
      template: require('./details/topicosverificacao/details/details.view.html'),
      controller: 'DetalhesTopicosVerificacaoController',
      controllerAs: 'vm',
      role: 'elvListarTopicosVerificacao'
    });
}

routes.$inject = ['$stateProvider'];
