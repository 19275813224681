export function routes($stateProvider) {
  $stateProvider
    .state('app.obler.oi', {
      url: '/processos',
      abstract: true
    })
    .state('app.obler.oi.list', {
      url: '?{page:int}&{items:int}&{state:int}&order&sort&filter',
      title: 'Processos',
      template: require('./list/view.html'),
      role: ['oblerVerOIs'],
      controller: 'OblOIListController',
      controllerAs: 'vm',
      params: {
        state: {
          value: 1,
          dynamic: true
        },
        page: {
          value: 1,
          dynamic: true
        },
        items: {
          value: 20,
          dynamic: true
        },
        order: {
          value: "id",
          dynamic: true
        },
        sort: {
          value: "desc",
          dynamic: true
        },
        filter: {
          dynamic: true
        }
      }
    })
    .state('app.obler.oi.details', {
      url: '/{id:int}',
      title: 'Detalhes do Processo',
      template: require('./details/view.html'),
      role: ['oblerVerOIs'],
      controller: 'OblOIDetailsController',
      controllerAs: 'vm',
    })
    .state('app.obler.oi.new', {
      url: '/new',
      title: 'Novo Processo',
      template: require('./new/view.html'),
      role: ['oblerCriarOIs'],
      controller: 'OblOINewController',
      controllerAs: 'vm',
    })
    .state('app.obler.oi.folha', {
      url: '/{id:int}/folha/{ficha:int}',
      title: 'Folha de trabalho',
      template: require('./folha/view.html'),
      role: ['oblerVerProcessos'],
      controller: 'OblOrcamentacaoController',
      controllerAs: 'vm',
    });
}

routes.$inject = ['$stateProvider'];
