import { routes } from './routes'

import FunOIListController from './list/controller';
import FunOIDetailsController from './details/controller';

export default angular.module('app.funciona.oi', [])
  .config(routes)
  .controller('FunOIListController', FunOIListController)
  .controller('FunOIDetailsController', FunOIDetailsController)
  .name;
