export function routes($stateProvider) {
  $stateProvider
    .state('app.elv.calendarizacao', {
      url: '/calendarizacao',
      title: 'Calendarização',
      template: require('./calendar.view.html'),
      controller: 'CalendarizacaoElevadoresController',
      controllerAs: 'vm',
      role: 'elvCalendarizacao'
    });
}

routes.$inject = ['$stateProvider'];
