import { routes } from './routes'

import GasOrdemIntervencao from './ordensintervencao';
import GasProcessos from './processos';
import GasClientes from './clientes';
import GasRelatorios from './relatorios';
import GasAgendamentos from './agendamentos';
import GasDistribuidores from './distribuidores';
import GasOrds from './ords';

import GasService from "./gas.service.ts";

export default angular.module('app.gas', [GasOrdemIntervencao, GasProcessos, GasClientes, GasRelatorios, GasAgendamentos, GasDistribuidores, GasOrds])
  .config(routes)
  .service("GasService", GasService)
  .name;
