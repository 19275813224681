import {routes} from './familias.routes';
import AtvFamiliasListController from "./directory/directory.controller";
import AtvFamiliasDetailsController from "./details/details.controller";
import AtvSubfamiliaDetailsController from "./subfamilias/details.controller";

export default angular.module('app.ativos.familias', [])
  .config(routes)
  .controller('AtvFamiliasListController', AtvFamiliasListController)
  .controller('AtvFamiliasDetailsController', AtvFamiliasDetailsController)
  .controller('AtvSubfamiliaDetailsController', AtvSubfamiliaDetailsController)
  .name;
