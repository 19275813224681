export function routes($stateProvider) {
  $stateProvider
    .state('app.ativos.interacoes', {
      url: '/interacoes',
      abstract: true,
      template: '<div ui-view></div>'
    })
    .state('app.ativos.interacoes.list', {
      url: '?{page:int}&{items:int}&{state:int}&order&sort&filter',
      title: 'Interações',
      template: require('./directory/directory.view.html'),
      controller: 'AtvInteracoesListController',
      controllerAs: 'vm',
      role: ['atvListarAtivos', 'atvListarInteracoes'],
      params: {
        state: {
          value: 1,
          dynamic: true
        },
        page: {
          value: 1,
          dynamic: true
        },
        items: {
          value: 20,
          dynamic: true
        },
        order: {
          value: "dataRegisto",
          dynamic: true
        },
        sort: {
          value: "desc",
          dynamic: true
        },
        filter: {
          dynamic: true
        }
      }
    })
    .state('app.ativos.interacoes.details', {
      url: '/{id}',
      controller: 'AtvInteracoesDetailsController',
      controllerAs: 'vm',
      template: require('./details/details.view.html'),
      role: ['atvListarAtivos', 'atvListarInteracoes']
    })
  ;
}

routes.$inject = ['$stateProvider'];
