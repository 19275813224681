import { Box, Breadcrumbs, Link, MenuItem, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Table } from '../../../../interface/components';
let first = true;
const ListOrcamentos = ({ $state, OblerRevampService, UIService }) => {
    const loadParams = () => {
        const sortBy = $state.params.sortBy;
        if (sortBy == undefined) {
            return [];
        }
        const o = [];
        sortBy.forEach(r => {
            const s = r.split(':');
            o.push({ id: s[0], desc: s[1] == 'DESC' });
        });
        return o;
    };
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [refetching, setRefetching] = useState(false);
    const [options, setOptions] = useState({
        filter: [],
        sort: []
    });
    const [pagination, setPagination] = useState({
        pageIndex: $state.params.page - 1 || 0,
        pageSize: $state.params.limit || 20
    });
    const [filter, setFilter] = useState([]);
    const [sort, setSort] = useState(() => loadParams());
    const validate = async (row) => { };
    const getOrcamentoEstado = row => {
        if (!row || row.dataSubmissao == undefined)
            return '-';
        switch (row.aceite) {
            case 0:
                return 'Não aprovado';
            case 1:
                return 'Aprovado';
            case 2:
                return 'Aguarda resposta do cliente';
            case 3:
                return 'Pendente de fecho';
        }
    };
    const getType = (type) => {
        switch (type) {
            case 'contains':
                return '$ilike';
            case 'startsWith':
                return '$sw';
            case 'notEquals':
                return '$not';
            case 'empty':
                return '$null';
            case 'notEmpty':
                return '$notNull';
            case 'greaterThan':
                return '$gt';
            case 'greaterThanOrEqualTo':
                return '$gte';
            case 'lessThan':
                return '$lt';
            case 'lessThanOrEqualTo':
                return '$lte';
            default:
                return '$eq';
        }
    };
    const initialFetch = async () => {
        if (loading) {
            const opt = await OblerRevampService.options();
            setOptions(opt);
            setLoading(false);
        }
    };
    const fetchData = async () => {
        try {
            let filters = {};
            filter.forEach(r => {
                filters[r.id] = `${getType(r.type)}:${r.value}`;
            });
            let sorter = [];
            sort.forEach(r => {
                sorter.push(`${r.id}:${r.desc ? 'DESC' : 'ASC'}`);
            });
            const aux = await OblerRevampService.findOrcamentos({
                limit: pagination.pageSize,
                page: pagination.pageIndex + 1,
                filter: filters,
                sortBy: sorter
            });
            $state.go($state.current.name, {
                limit: pagination.pageSize,
                page: pagination.pageIndex + 1,
                sortBy: sorter,
                filter: Object.keys(filter).length > 0 ? JSON.stringify(filters) : undefined
            }, {
                notify: false,
                reload: false,
                location: 'replace',
                inherit: true
            });
            // Update URL
            setData(aux);
        }
        catch (e) { }
        setRefetching(false);
    };
    useEffect(() => {
        initialFetch();
        if (!refetching) {
            setRefetching(true);
            fetchData();
        }
    }, [pagination, filter, sort]);
    return (<Box>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Typography variant='h5'>Listagem</Typography>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link underline='none' color='inherit'>
            Obler
          </Link>
          <Link underline='hover' color='text.primary' aria-current='page'>
            Orçamentos
          </Link>
        </Breadcrumbs>
      </Box>

      <Box>
        <Table onDoubleClick={row => {
            $state.go('app.obler.orcamentos.details', {
                id: row.id
            });
        }} storageKey={$state.current.name} withRowStyle={row => {
            const isWaiting = row.tipoId == 2 && row.extra == 0 && row.dataSubmissao != null && row.aceite == 2;
            const isPending = row.tipoId == 2 && row.extra == 0 && row.dataSubmissao != null && row.aceite == 3;
            if (isWaiting) {
                return {
                    sx: {
                        backgroundColor: '#FFDE03'
                    }
                };
            }
            if (isPending) {
                return {
                    sx: {
                        backgroundColor: '#B00020',
                        '& td': {
                            color: '#FFF'
                        }
                    }
                };
            }
            return {};
        }} data={data} columns={[
            {
                accessorKey: 'id',
                header: '#',
                size: 50
            },
            {
                accessorKey: 'Ordemintervencao.numeroProcesso',
                header: 'Processo',
                size: 160
            },
            {
                accessorKey: 'Ordemintervencao.parceiro',
                header: 'Parceiro'
            },
            {
                accessorKey: 'Ordemintervencao.nomeCliente',
                header: 'Proprietário'
            },
            {
                accessorKey: 'Ordemintervencao.nifCliente',
                header: 'NIF Proprietário'
            },
            {
                accessorKey: 'Ordemintervencao.morada',
                header: 'Morada'
            },
            {
                accessorKey: 'Ordemintervencao.localidade',
                header: 'Localidade'
            },
            {
                accessorKey: 'Ordemintervencao.cp4',
                header: 'Cód. Postal'
            },
            {
                accessorKey: 'Ordemintervencao.cp3',
                header: 'Ext. Postal'
            },
            {
                accessorKey: 'Agendamento.data',
                header: 'Data de inspeção',
                Cell: ({ cell }) => {
                    const value = cell.getValue();
                    if (value != undefined)
                        return moment(value).format('DD-MM-YYYY');
                    return '-';
                }
            },
            {
                accessorKey: 'Agendamento.hora',
                header: 'Hora de inspeção',
                Cell: ({ cell }) => {
                    const value = cell.getValue();
                    if (value != undefined)
                        return value.substring(0, 5);
                    return '-';
                }
            },
            {
                accessorKey: 'Agendamento.Funcionario.id',
                header: 'Inspetor',
                filterSelectOptions: [
                    { label: 'António Gomes', value: 114 },
                    { label: 'Tiago Ribeiro', value: 112 }
                ],
                Cell: ({ row }) => {
                    return row.original.Agendamento?.Funcionario?.name;
                }
            },
            {
                accessorKey: 'realizada',
                header: 'Ida ao local?',
                Cell: ({ row }) => {
                    return row.original.realizada == 1 ? 'Sim' : 'Não';
                }
            },
            {
                accessorKey: 'aceite',
                header: 'Estado',
                filterSelectOptions: [
                    { label: 'Não aprovado', value: 0 },
                    { label: 'Aprovado', value: 1 },
                    { label: 'Aguarda resposta de cliente', value: 2 },
                    { label: 'Pendente de fecho', value: 3 }
                ],
                Cell: ({ row }) => {
                    return getOrcamentoEstado(row.original);
                }
            }
        ]} initial={{
            sort,
            filter
        }} cellActionItems={({ row }) => {
            return [
                <MenuItem key={`row.view.${row.id}`} onClick={() => {
                        $state.go('app.obler.orcamentos.details', {
                            id: row.id
                        });
                    }}>
                Visualizar
              </MenuItem>,
                <MenuItem key={`row.oi.${row.id}`} onClick={() => validate(row.original)}>
                Ir para processo
              </MenuItem>
            ];
        }} isLoading={refetching} options={options} onPaginate={setPagination} onFilter={setFilter} onSort={setSort}></Table>
      </Box>
    </Box>);
};
export default ListOrcamentos;
