export function routes($stateProvider) {
  $stateProvider
    .state('app.elv.pontos', {
      url: '/pontos',
      abstract: true,
      template: '<div ui-view></div>'
    })
    .state('app.elv.pontos.list', {
      url: '/',
      title: 'Gestão de Pontos',
      template: require('./directory/directory.view.html'),
      controller: 'DirectoryPontosController',
      controllerAs: 'vm',
      role: 'elvListarPontosVerificacao'
    })
}

routes.$inject = ['$stateProvider'];
