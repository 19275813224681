export function routes($stateProvider) {
  $stateProvider
    .state('app.formacao', {
      url: '/formacao',
      abstract: true,
      template: '<div ui-view></div>'
    })
    .state('app.formacao.cursos', {
      url: '/cursos',
      abstract: true,
      template: '<div ui-view></div>'
    })
    .state('app.formacao.cursos.list', {
      url: '/',
      title: 'Lista de Cursos',
      template: require('./cursos/directory/directory.view.html'),
      controller: 'FormacaoCursosController',
      controllerAs: 'vm',
      role: 'cursosFormacaoList'
    })
    .state('app.formacao.cursos.details', {
      url: '/{id}',
      title: 'Detalhes de Curso',
      template: require('./cursos/details/details.view.html'),
      controller: 'FormacaoCursoDetailsController',
      controllerAs: 'vm',
      role: 'cursosFormacaoList'
    })
    .state('app.formacao.cursos.edicao', {
      url: '/{id}/edicao',
      abstract: true,
      template: '<div ui-view></div>'
    })
    .state('app.formacao.cursos.edicao.details', {
      url: '/{ed}',
      title: 'Cursos - Edição',
      template: require('./cursos/edicao/details/details.view.html'),
      controller: 'FormacaoCursoEdicaoController',
      controllerAs: 'vm',
      role: 'cursosFormacaoList'
    })
    .state('app.formacao.cursos.currenteditions', {
      url: '/edicoesatuais',
      title: 'Cursos - Edições Atuais',
      template: require('./cursos/currenteditions/currenteditions.view.html'),
      controller: 'FormacaoCursosCurrentEditionsController',
      controllerAs: 'vm',
      role: 'cursosFormacaoList'
    })
    .state('app.formacao.cursos.edicao.agendamento', {
      url: '/{ed}/agendamento',
      title: 'Agendamento de Edição',
      template: require('./cursos/edicao/agendamento/agendamento.view.html'),
      controller: 'FormacaoCursoEdicaoAgendamentoController',
      controllerAs: 'vm',
      role: 'cursosFormacaoList'
    })
    .state('app.formacao.modulos', {
      url: '/modulos',
      abstract: true,
      template: '<div ui-view></div>'
    })
    .state('app.formacao.modulos.details', {
      url: '/{id}',
      params: {cursoId: null, edicaoId: null},
      title: 'Cursos - Edição - Módulo',
      template: require('./cursos/edicao/modulo/details/details.view.html'),
      controller: 'FormacaoCursoEdicaoModuloController',
      controllerAs: 'vm',
      role: 'cursosFormacaoList'
    })
    .state('app.formacao.locais', {
      url: '/locais',
      abstract: true,
      template: '<div ui-view></div>'
    })
    .state('app.formacao.locais.list', {
      url: '/',
      title: 'Lista de Locais',
      template: require('./locais/directory/directory.view.html'),
      controller: 'FormacaoLocaisController',
      controllerAs: 'vm',
      role: 'locaisFormacaoList'
    })
    .state('app.formacao.locais.details', {
      url: '/{id}',
      title: 'Detalhes de Local',
      template: require('./locais/details/details.view.html'),
      controller: 'FormacaoLocalDetailsController',
      controllerAs: 'vm',
      role: 'locaisFormacaoList'
    })
    .state('app.formacao.formadores', {
      url: '/formadores',
      abstract: true,
      template: '<div ui-view></div>'
    })
    .state('app.formacao.formadores.list', {
      url: '/',
      title: 'Lista de Formadores',
      template: require('./formadores/directory/directory.view.html'),
      controller: 'FormacaoFormadoresController',
      controllerAs: 'vm',
      role: 'cursosFormacaoList'
    })
    .state('app.formacao.formadores.details', {
      url: '/{id}',
      title: 'Detalhes de Formador',
      template: require('./formadores/details/details.view.html'),
      controller: 'FormacaoFormadorDetailsController',
      controllerAs: 'vm',
      role: 'cursosFormacaoList'
    })
    .state('app.formacao.formandos', {
      url: '/formandos',
      abstract: true,
      template: '<div ui-view></div>'
    })
    .state('app.formacao.formandos.list', {
      url: '/',
      title: 'Formandos',
      template: require('./formandos/directory/directory.view.html'),
      controller: 'FormacaoFormandosController',
      controllerAs: 'vm',
      role: 'cursosFormacaoList'
    })
    .state('app.formacao.formandos.details', {
      url: '/{id}',
      title: 'Detalhes de Formando',
      template: require('./formandos/details/details.view.html'),
      controller: 'FormacaoFormandoDetailsController',
      controllerAs: 'vm',
      role: 'cursosFormacaoList'
    })
    .state('app.formacao.formandos.new', {
      url: '/novo',
      title: 'Adicionar Formando',
      template: require('./formandos/new/new.view.html'),
      controller: 'FormacaoCreateFormandoController',
      controllerAs: 'vm',
      role: 'cursosFormacaoList'
    })
    .state('app.formacao.formandos.new.1', {
      template: require('./formandos/new/details.form.html')
    })
    .state('app.formacao.formandos.new.2', {
      template: require('./formandos/new/company.form.html')
    })
    .state('app.formacao.formandos.new.3', {
      template: require('./formandos/new/others.form.html')
    })
    .state('app.formacao.areas', {
      url: '/areas',
      abstract: true,
      template: '<div ui-view></div>'
    })
    .state('app.formacao.areas.list', {
      url: '/',
      title: 'Áreas de Formação',
      template: require('./areas/directory/directory.view.html'),
      controller: 'FormacaoAreasController',
      role: 'cursosFormacaoList',
      controllerAs: 'vm'
    })
    .state('app.formacao.areas.details', {
      url: '/:id',
      title: 'Áreas de Formação',
      template: require('./areas/details/details.view.html'),
      controller: 'FormacaoAreaDetailsController',
      role: 'cursosFormacaoList',
      controllerAs: 'vm'
    })
    .state('app.formacao.empresas', {
      url: '/empresas',
      abstract: true,
      template: '<div ui-view></div>'
    })
    .state('app.formacao.empresas.list', {
      url: '/',
      title: 'Lista de Empresas',
      template: require('./empresas/directory/directory.view.html'),
      controller: 'FormacaoEmpresasController',
      role: 'cursosFormacaoList',
      controllerAs: 'vm'
    })
    .state('app.formacao.empresas.details', {
      url: '/:id',
      title: 'Detalhes da Empresa',
      template: require('./empresas/details/details.view.html'),
      controller: 'FormacaoEmpresaDetailsController',
      role: 'cursosFormacaoList',
      controllerAs: 'vm'
    })
  ;
}

routes.$inject = ['$stateProvider'];
