routes.$inject = ['$stateProvider'];

export function routes($stateProvider) {

  $stateProvider
    .state('app.mensagens-IE', {
      url: '/mensagens',
      title: 'Visualizar mensagens',
      template: require('./directory/directory.view.html'),
      controller: 'DirectoryMensagensController',
      role: 'mensagensIeList',
      controllerAs: 'vm'
    });

}


routes.$inject = ['$stateProvider'];
