export function routes($stateProvider) {
  $stateProvider
    .state('app.gas.distribuidores', {
      url: '/distribuidores',
      abstract: true
    })
    .state('app.gas.distribuidores.list', {
      url: '?{page:int}&{items:int}&{state:int}&order&sort&filter',
      title: 'Distribuidores',
      template: '<gas-distribuidores-list-view></gas-distribuidores-list-view>',
      // role: ['gasVerDistribuidores'],
      params: {
        state: {
          value: 1,
          dynamic: true
        },
        page: {
          value: 1,
          dynamic: true
        },
        items: {
          value: 20,
          dynamic: true
        },
        order: {
          value: "id",
          dynamic: true
        },
        sort: {
          value: "desc",
          dynamic: true
        },
        filter: {
          dynamic: true
        }
      }
    })
    .state('app.gas.distribuidores.details', {
      url: '/{id:int}',
      title: 'Detalhes do Distribuidor',
      template: '<gas-distribuidor-details-view></gas-distribuidor-details-view>',
      // role: ['gasVerDistribuidores'],
    });
}

routes.$inject = ['$stateProvider'];
