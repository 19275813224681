import { routes } from './routes';

import FunProcessosListController from './list/controller';
import FunProcessosDetailsController from './details/controller';
import FunImporterController  from './list/import/controller';


export default angular.module('app.funciona.processos', [])
  .config(routes)
  .controller('FunProcessosListController', FunProcessosListController)
  .controller('FunProcessosDetailsController', FunProcessosDetailsController)
  .controller('FunImporterController', FunImporterController)
  .name;
