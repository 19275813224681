import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid';
import * as yup from 'yup';
import { useFormik } from 'formik';
const DialogClienteFinal = ({ open, initial, onClose }) => {
    const validationSchema = yup.object({
        nome: yup.string().required('O campo é obrigatório'),
        nif: yup.string().min(9, 'NIF tem de ter 9 dígitos').required('O campo é obrigatório'),
        email: yup.string().email('Email inválido').notRequired(),
        contacto: yup.string().notRequired(),
        morada: yup.string().required('O campo é obrigatório'),
        cep: yup.string().matches(/^\d{4}-\d{3}$/, 'Código Postal deve estar no formato XXXX-YYY').required('O campo é obrigatório'),
        localidade: yup.string().required('O campo é obrigatório'),
        concelho: yup.string().required('O campo é obrigatório'),
        distrito: yup.string().required('O campo é obrigatório'),
        observacoes: yup.string().required('O campo é obrigatório'),
    });
    const formik = useFormik({
        initialValues: {},
        validationSchema: validationSchema,
        validateOnMount: false,
        onSubmit: async (values) => {
            onClose(values);
        }
    });
    useEffect(() => {
        formik.setValues(initial);
    }, [initial]);
    return (<form>
      <Dialog open={open} maxWidth={'lg'} fullWidth>
        <DialogTitle>Editar Informação de Cliente</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={8} md={8} xs={8}>
              <TextField label='Nome' name='nome' fullWidth defaultValue={formik.values?.nome} onChange={formik.handleChange} onBlur={formik.handleBlur} error={formik.touched.nome && Boolean(formik.errors.nome)} helperText={formik.touched.nome && formik.errors.nome}/>
            </Grid>
            <Grid lg={4} md={4} xs={4}>
              <TextField label='NIF' name='nif' defaultValue={formik.values?.nif} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.nif && Boolean(formik.errors.nif)} helperText={formik.touched.nif && formik.errors.nif}/>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={6} md={6} xs={12}>
              <TextField label='Email' name='email' defaultValue={formik.values?.email} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.email && Boolean(formik.errors.email)} helperText={formik.touched.email && formik.errors.email}/>
            </Grid>
            <Grid lg={6} md={6} xs={12}>
              <TextField label='Contacto' name='contacto' defaultValue={formik.values?.contacto} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.contacto && Boolean(formik.errors.contacto)} helperText={formik.touched.contacto && formik.errors.contacto}/>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid lg={12} md={12} xs={12}>
              <TextField label='Morada' name='morada' defaultValue={formik.values?.morada} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.morada && Boolean(formik.errors.morada)} helperText={formik.touched.morada && formik.errors.morada}/>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={2} md={2} xs={12}>
              <TextField label='Código Postal' name='cep' defaultValue={formik.values?.cep} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.cep && Boolean(formik.errors.cep)} helperText={formik.touched.cep && formik.errors.cep}/>
            </Grid>
            <Grid lg={4} md={6} xs={12}>
              <TextField label='Localidade' name='localidade' defaultValue={formik.values?.localidade} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.localidade && Boolean(formik.errors.localidade)} helperText={formik.touched.localidade && formik.errors.localidade}/>
            </Grid>
            <Grid lg={3} md={6} xs={12}>
              <TextField label='Concelho' name='concelho' defaultValue={formik.values?.concelho} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.concelho && Boolean(formik.errors.concelho)} helperText={formik.touched.concelho && formik.errors.concelho}/>
            </Grid>
            <Grid lg={3} md={6} xs={12}>
              <TextField label='Distrito' name='distrito' defaultValue={formik.values?.distrito} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.distrito && Boolean(formik.errors.distrito)} helperText={formik.touched.distrito && formik.errors.distrito}/>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid lg={12} md={12} xs={12}>
              <TextField label='Observações' name='observacoes' defaultValue={formik.values?.observacoes} onChange={formik.handleChange} onBlur={formik.handleBlur} fullWidth error={formik.touched.observacoes && Boolean(formik.errors.observacoes)} helperText={formik.touched.observacoes && formik.errors.observacoes}/>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type='button' onClick={() => onClose(undefined)}>
            Cancelar
          </Button>
          <Button onClick={() => {
            formik.handleSubmit();
        }} disabled={!formik.isValid || !formik.dirty || formik.isSubmitting} variant='contained'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </form>);
};
export default DialogClienteFinal;
