import { routes } from './routes'

import OblViaturaListController from './list/controller';
import OblViaturaDetailsController from './details/controller';

export default angular.module('app.obler.viatura', [])
  .config(routes)
  .controller('OblViaturaListController', OblViaturaListController)
  .controller('OblViaturaDetailsController', OblViaturaDetailsController)
  .name;
